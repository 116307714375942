import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VNavigationDrawer,{staticClass:"primary lighten-4",attrs:{"temporary":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c('ContactForm',{ref:"ContactFormButton"}),_c(VListItemGroup,[_c(VListItem,{staticStyle:{"margin-top":"20px","margin-left":"-2px"}},[_c(VAppBarNavIcon,{attrs:{"color":"black"},on:{"click":function($event){return _vm.closeDrawer()}}},[_c(VImg,{staticClass:"d-flex justify-start",attrs:{"contain":"","width":"45px","src":require("../assets/list_black_24dp.svg")}})],1)],1),_c(VListItem,{staticClass:"drawerMarginLeft listItemFont"},[_c(VListItemTitle,[_c(VBtn,{attrs:{"plain":"","href":"https://app.followup.prios.no/#/login","target":"_blank"}},[_vm._v(" Log In ")])],1)],1),_c(VListItem,{staticClass:"drawerMarginLeft listItemFont"},[_c(VListItemTitle,[_c(VBtn,{attrs:{"plain":"","to":"/"}},[_vm._v(" Home ")])],1)],1),_c(VListItem,{staticClass:"drawerMarginLeft listItemFont"},[_c(VListItemTitle,[_c(VBtn,{attrs:{"plain":"","to":"/about"}},[_vm._v(" About ")])],1)],1),_c(VListItem,{staticClass:"drawerMarginLeft listItemFont"},[_c(VListItemTitle,[_c(VBtn,{attrs:{"plain":"","to":"/news"}},[_vm._v(" News ")])],1)],1),_c(VListItem,{staticClass:"drawerMarginLeft listItemFont"},[_c(VListItemTitle,[_c(VBtn,{attrs:{"plain":"","to":"outputs"}},[_vm._v(" Outputs ")])],1)],1),_c(VListItem,{staticClass:"drawerMarginLeft listItemFont"},[_c(VListItemTitle,[_c(VBtn,{attrs:{"plain":"","to":"mappingtool"}},[_vm._v(" Mapping Tools ")])],1)],1),_c(VListItem,{staticClass:"drawerMarginLeft listItemFont"},[_c(VListItemTitle,[_c(VBtn,{staticClass:"mr-1",attrs:{"plain":""},on:{"click":function($event){return _vm.$refs.ContactFormButton.openDialog()}}},[_vm._v(" Contact Us ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }