import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('p',{staticClass:"title text-center mt-10 mb-10"},[_vm._v("Mapping tool")]),_c('p',[_vm._v(" Here you will find the mapping tool to measure your school's digital capabilities from the point of view of the main sections identified by the project (see the Strategy Manual). The mapping tool has the form of a questionnaire that can lead to obtaining the \"Digital Friendly School\" label. ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Please, be aware that, after clicking on “open mapping tools”, on the preferred language banner, you can: ")]),_vm._m(0),_c('p',{staticClass:"pt-3 mb-0"},[_vm._v(" Please note that once all the questions have been answered, the school will receive an email with the final result (in pdf format) with the specified score (the mapping) for each sector: ")]),_vm._m(1),_vm._m(2),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}}),_vm._l((_vm.mappingCards),function(card,index){return _c(VCol,{key:index,attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c(VCard,{staticStyle:{"background-color":"rgb(241, 237, 239)"}},[_c(VCardTitle,[_vm._v(" "+_vm._s(card.title)+" "),_c('img',{staticClass:"ml-2",attrs:{"src":card.flag,"width":"30px"}})]),_c(VCardText,[_vm._v(" "+_vm._s(card.description)+" ")]),_c(VCardActions,[_c(VBtn,{on:{"click":function($event){return _vm.$refs.openMappingDialog.openingMappingForm(card.id)}}},[_vm._v(" Open Mapping Tool ")])],1)],1)],1)}),_c(VCol,{attrs:{"cols":"12"}})],2),_c('MappingToolMainComponent',{ref:"openMappingDialog"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("fill in all the required data and click on \"take mapping tool\".")]),_c('li',[_vm._v(" start answering the questions of the questionnaire divided into the six main sections of the Strategy Manual ")]),_c('li',[_vm._v(" save the answers with the button at the bottom and then later retrieve the questionnaire and complete it ")]),_c('li',[_vm._v(" once concluded, send with the \"Submit (Crucial)\" button always at the bottom ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v(" In case of a positive result, the Digital Friendly School label will be assigned ")]),_c('li',[_vm._v(" in the event of a negative result, the school will be invited at improving its weaknesses and to repeat the test after, at least, six months ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"pt-3"},[_vm._v(" Further details are reported in the booklet “Digital Friendly School - Instructions for the Label” downloadable, in your preferred language, "),_c('br'),_vm._v(" here: "),_c('a',{attrs:{"href":"https://reset-project.eu/outputs"}},[_vm._v("https://reset-project.eu/outputs")]),_vm._v(" > in the IO4 section ")])
}]

export { render, staticRenderFns }