<template>
<v-app>


<v-main>
  <Outputs></Outputs>
</v-main>

</v-app>
</template>

<script>
  import Outputs from '../components/Outputs'
  


  export default {
    name: 'Home',

    components: {
      Outputs,
    },
  }
</script>

<style scoped>
 * {
  overflow: hidden;
 }
</style>