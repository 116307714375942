<template>
<v-app>


<v-main>
  <TrainingModules></TrainingModules>
</v-main>

</v-app>
</template>

<script>
  import TrainingModules from '../components/TrainingModules'
  


  export default {
    name: 'Home',

    components: {
      TrainingModules,
    },
  }
</script>