<template>
  <v-card class="ma-0" flat>
    <ImageDialog ref="openImage"></ImageDialog>
    <v-col cols="12">
      <p class="title text-center pb-5 pt-5 ml-3 mr-3 mainHeaders">Tutorial videos on how to apply digital teaching through digital tools</p>
      <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px;"></v-divider>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col ols="12" xl="3" lg="3" md="3" sm="12" xs="12" v-for="(tempFour, tempFourIndex) in templateFourContent" :key="tempFourIndex" @click="templateFourChange(tempFour.value)">
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 12 : 0" style="cursor:pointer; background-color: lightblue;" height="100%" class="pa-2">
              <p class="mb-0">{{tempFour.description}}</p>
            </v-card>
          </v-hover>
        </v-col>
        <!-- <v-col cols="12" class="ma-0 pa-0">
          <p class="pt-15 title text-center">SELFIE for teachers</p>
        </v-col> -->
      </v-row>
    </v-col>

    <!-- Sub chapter 1 / 9 -->
    <template v-if="subTemplateOneID == 1">
      <v-col cols="12" class="ma-0 pa-0">
        <p class="title text-center pt-5 pb-5 mb-5 mt-5 subHeaders">Steps on Creating tutorial videos:</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
        <p>
          Tutorial videos are the go-to instructional method for teaching a process or providing step-by-step instructions. 
          Usually, between 2-5 minutes long, tutorial videos may leverage multiple instructional methods. 
          Sometimes referred to as “how-to” videos, the best ones are carefully planned and have a professional touch. 
          Breaking down a process with video makes it easy for your audience to understand exactly what to do and how. 
          A DIY video takes your audience through each step in a process, so they won't get confused or lost, the way they might with just text or static images.
        </p>

        <span class="title dropDownText mb-0" @click="stepperOneDropper()">Step 1: Determine and get to know your audience</span>
        <span v-if="isStepOneVisible == false">⬇️</span>
        <span v-else>⬆️</span>
        <br>
        <div v-if="isStepOneVisible">
          <p>
            Typically, at the beginning you have to address the video to your audience, line up the objectives, the expectations, and the results. 
            You have to know and understand who you are talking to, and to do so, you will need information that ranges from demographics (age, gender, nationality, the scope of work, etc.) to preferences, personal interests, values, likes, and dislikes. 
            You might find it hard to believe, but even online behavior plays an important role here! 
            There are a lot of tools, but in this case, we know well our audience, so we can do our research easily. 
            By determining your target audience, you will figure out if your content is relevant, appealing, and appropriate for them. 
            Remember that having great ideas is not as good as having the RIGHT ideas. When you’re more familiar with your viewers, you can create videos that resonate with them and get across your message more effectively.
          </p>
        </div>

        <span class="title dropDownText mb-0" @click="stepperTwoDropper()">Step 2: Write a storyboard and script</span>
        <span v-if="isStepTwoVisible == false">⬇️</span>
        <span v-else>⬆️</span>
        <br>
        <div v-if="isStepTwoVisible">
          <p>
            The beforehand made screenplay will give certain key visual instructions, it's not meant to show every single shot, every camera movement, every close-up or every edit. 
            Could be a presentation format (animated as well) supported with an explanation of your concept, or shows someone how to do something. 
            Place your starting point. 
            Scripts all contain similar types of information, like who’s speaking, what’s being said, where it’s being said, and other critical pieces of information. 
            When you write a script, you can use whatever format best works for your needs. 
            Your scrip requires answering these questions:
          </p>
          <p class="pl-2">
            <span> •  What is your topic?</span><br>
            <span> •  Who is my audience?</span><br>
            <span> •  topic? - Make sure you know why your audience will care. This will ensure you address their concerns and reasons for watching the video. </span><br>
            <span> •  What is the learning objective?</span><br>
            <span> •  What specific problem will the tutorial solve or suggest how to proceed?</span><br>
            <span> •  What will the audience be able to do after watching this?</span><br>
            <span> •  How does it benefit them?</span>
          </p>
          <p class="mb-0 font-weight-bold"> Your script should include a few components: </p>
          <p class="pl-2">
            <span> - The words that will be spoken; </span> <br>
            <span> - Information about the words; </span> <br>
            <span> - Where they are said; </span> <br>
            <span> - Tow they are said; </span>
          </p>
        </div>

        <span class="title dropDownText mb-0" @click="stepperThreeDropper()">Step 3: Record your narration</span>
        <span v-if="isStepThreeVisible == false">⬇️</span>
        <span v-else>⬆️</span>
        <br>
        <div v-if="isStepThreeVisible">
          <p>
            Most people who end up making videos didn’t expect that someday they’d be making videos. 
            They stumbled upon the job. 
            The result of this is a lot of people don’t approach their first video with a great result. 
            Do not give and try again!
          </p>
          <p class="font-weight-bold mb-0">1. Record Voice over and Add it to Video</p>
          <p>
            In most cases, we recommend you to record the voice over first, so the sound will be more natural. 
            <a href="https://filmora.wondershare.com/" target="_blank">Filmora</a> is a great tool to do it, but you can use a program that you know or got used to.
          </p>
          <p class="font-weight-bold">2. Connect Microphone to computer</p>
          <p>
            Plug the microphone into your computer when you’re ready to get started. 
            It's better to use some professional external microphones to capture high-quality sound. 
            If not, you can use the mic from the program.
          </p>
          <p class="font-weight-bold mb-0">3. Record voice over</p>
          <p>
            If you use <a href="https://filmora.wondershare.com/" target="_blank">Filmora</a> go to a “New Project” to create a new one. 
            Go to the Record tab, and then click “Record a Voice over ” option to arouse the Record Audio windows. 
            You could also use an animation voice over. 
            The principle of the other recording programs is the same.
          </p>
        </div>

        <span class="title dropDownText mb-0" @click="stepperFourDropper()">Step 4: Record your screen</span>
        <span v-if="isStepFourVisible == false">⬇️</span>
        <span v-else>⬆️</span>
        <br>
        <div v-if="isStepFourVisible">
          <p>
            You can use <a href="https://www.loom.com/" target="_blank">Loom</a> or <a href="https://vimeo.com/" target="_blank">Vimeo</a> <strong>(just recommendations)</strong>
          </p>
          <p class="font-weight-bold mb-0">Easy steps: </p>
          <p class="pl-2">
            <span> •  Capture the screen with Screen Recorder.</span><br>
            <span> •  Open the recordings in Video Editor Plus.</span><br>
            <span> •  You can use your own created avatar instead of your real face.</span><br>
            <span> •  Use the recordings to create a video by trimming, cutting, and joining clips.</span><br>
            <span> •  Add animated titles and special effects.</span><br>
            <span> •  Save your clip in Full HD or 4K on your desktop or a file.</span>
          </p>
          <p class="font-weight-bold"> Produce and share</p>
        </div>
      </v-col>
    </template>

    <!-- Sub chapter 2 / 9 -->
    <template v-if="subTemplateOneID == 2">
      <!-- <v-col cols="12" class="ma-0 pa-0">
        <p class="pt-15 title text-center">How to create interactive digital lesson</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
        <p>Which tool and example topic - <strong>ValueMe, Bulgaria.</strong></p>
        <p>
          We believe teaching is the most important job in the world. 
          Interactive teaching happens with the interactive tools, resources, and content teachers need, all in one place. 
          It is no exaggeration to say that almost every teacher in the world is facing new teaching realities. 
          Engaging students is now harder than ever. 
          But it’s not impossible! Creating interactive lesson materials is a great solution for a remote class, especially in asynchronous learning models. 
          The current situation requires tons of flexibility on the teacher’s part. 
          One day we are teaching at school, but the next one we may be called to teach 100% online. 
          We need to be ready and offer the best possible experience to our students. 
          Being able to create our own digital materials is like being one step ahead of the game. 
          Digital lesson planning plays a huge role in providing students with a stable online classroom environment that best supports their learning. 
          No matter the age group, students respond best to predictable routines in which they are involved and aware of the process and are able to anticipate what comes next. 
          During unstable times, students look toward the routine of school to keep them on track. 
          Post your lesson plans in multiple visible places online where students, substitutes, and parents can all see them and easily stay caught up on your curriculum.
        </p>
      </v-col> -->


      <v-col cols="12" class="ma-0 pa-0">
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px; margin-top: 5px;"></v-divider>
        <iframe v-if="$vuetify.breakpoint.mdAndDown" width="400" height="300" src="https://www.youtube.com/embed/7KZgNpPwsww?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe v-else width="690" height="430" src="https://www.youtube.com/embed/7KZgNpPwsww?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-col>
    </template>

    <!-- Sub chapter 3 / 9 -->
    <template v-if="subTemplateOneID == 3">
      <v-col cols="12" class="ma-0 pa-0">
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px; margin-top: 5px;"></v-divider>
        <iframe v-if="$vuetify.breakpoint.mdAndDown" width="400" height="300" src="https://www.youtube.com/embed/zJWmfBEPw8g?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe v-else width="690" height="430" src="https://www.youtube.com/embed/zJWmfBEPw8g?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-col>
    </template>

    <!-- Sub chapter 4 / 9 -->
    <template v-if="subTemplateOneID == 4">
      <v-col cols="12" class="ma-0 pa-0">
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px; margin-top: 5px;"></v-divider>
        <iframe v-if="$vuetify.breakpoint.mdAndDown" width="400" height="300" src="https://www.youtube.com/embed/3gGXiQ-nPxo?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe v-else width="690" height="430" src="https://www.youtube.com/embed/3gGXiQ-nPxo?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-col>
    </template>

    <!-- Sub chapter 5 / 9 - How to create and evaluate online tests/quizzes/surveys (need) -->
    <template v-if="subTemplateOneID == 5">
      <v-col cols="12" class="ma-0 pa-0">
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px; margin-top: 5px;"></v-divider>
        <iframe v-if="$vuetify.breakpoint.mdAndDown" width="400" height="300" src="https://www.youtube.com/embed/5QgFCIG-1Rc?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe v-else width="690" height="430" src="https://www.youtube.com/embed/5QgFCIG-1Rc?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-col>
    </template>

     <!-- Sub chapter 6 / 9 -->
    <template v-if="subTemplateOneID == 6">
      <v-col cols="12" class="ma-0 pa-0">
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px; margin-top: 5px;"></v-divider>
        <iframe v-if="$vuetify.breakpoint.mdAndDown" width="400" height="300" src="https://www.youtube.com/embed/DeEnB0GPFTc?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe v-else width="690" height="430" src="https://www.youtube.com/embed/DeEnB0GPFTc?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-col>
    </template>

     <!-- Sub chapter 7 / 9 -->
    <template v-if="subTemplateOneID == 7">
      <v-col cols="12" class="ma-0 pa-0">
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px; margin-top: 5px;"></v-divider>
        <iframe v-if="$vuetify.breakpoint.mdAndDown" width="400" height="300" src="https://www.youtube.com/embed/k-tW9D4mdOY?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe v-else width="690" height="430" src="https://www.youtube.com/embed/k-tW9D4mdOY?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-col>
    </template>

     <!-- Sub chapter 8 / 9 -->
    <template v-if="subTemplateOneID == 8">
      <v-col cols="12" class="ma-0 pa-0">
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px; margin-top: 5px;"></v-divider>
        <iframe v-if="$vuetify.breakpoint.mdAndDown" width="400" height="300" src="https://www.youtube.com/embed/DeEnB0GPFTc?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe v-else width="690" height="430" src="https://www.youtube.com/embed/DeEnB0GPFTc?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-col>
    </template>

     <!-- Sub chapter 8 / 9 -->
    <template v-if="subTemplateOneID == 9">
      <v-col cols="12" class="ma-0 pa-0">
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px; margin-top: 5px;"></v-divider>
        <iframe v-if="$vuetify.breakpoint.mdAndDown" width="400" height="300" src="https://www.youtube.com/embed/XlaXnOMWezE?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe v-else width="690" height="430" src="https://www.youtube.com/embed/XlaXnOMWezE?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-col>
    </template>
  </v-card>
</template>

<script>
import ImageDialog from "@/components/SmartGoalPoster/SmartGoalImageDialog.vue"
export default {
  components: {
    ImageDialog
  },
  data(){
    return {
      studentEngagementModel: 'smartGoalPoster/studentEngagementModel.png',
      subTemplateOneID: 1,
      templateFourContent: [
        { description: "Steps on creating tutorial videos", image: "", value: 1},
        { description: "How to create interactive digital lesson 📺", image: "", value: 2},
        { description: "How to do an engaging presentation 📺", image: "", value: 3},
        { description: "How to monitor group projects 📺", image: "", value: 4},
        { description: "How to create and evaluate online tests/quizzes/surveys 📺", image: "", value: 5},
        { description: "How to create attractive video content 📺", image: "", value: 6},
        { description: "How to create digital portfolios 📺", image: "", value: 7},
        { description: "How to create infographics, posters, flyers and badges 📺", image: "", value: 8},
        { description: "How to do gamification as engaging learning methodology 📺", image: "", value: 9},
      ],
      // To display for the 1th cards, steps
      isStepOneVisible: false,
      isStepTwoVisible: false,
      isStepThreeVisible: false,
      isStepFourVisible: false,
    }
  },
  methods: {
    // For Category 2 sub template swaps
    templateFourChange(subID){
      this.subTemplateOneID = subID;
    },
    /*
      Creating individual stepper, over having a longer function based on value
    */
    stepperOneDropper(){
      if(this.isStepOneVisible == false) {
        this.isStepOneVisible = true;
      } else {
        this.isStepOneVisible = false;
      }
    },

    stepperTwoDropper(){
      if(this.isStepTwoVisible == false) {
        this.isStepTwoVisible = true;
      } else {
        this.isStepTwoVisible = false;
      }
    },

    stepperThreeDropper(){
      if(this.isStepThreeVisible == false) {
        this.isStepThreeVisible = true;
      } else {
        this.isStepThreeVisible = false;
      }
    },

    stepperFourDropper(){
      if(this.isStepFourVisible == false) {
        this.isStepFourVisible = true;
      } else {
        this.isStepFourVisible = false;
      }
    },
  }
}
</script>

<style scoped>
.closeButtonStyling {
  position:fixed; 
  top:30px; 
  right:30px; 
  z-index: 5; 
  border:1px solid black; 
  background-color:rgba(255, 0, 0, 0.2);
}
.returningToTop {
  position:fixed; 
  bottom:30px; 
  right:30px; 
  z-index: 5;
  border:1px solid black; 
  background-color:rgba(255, 0, 0, 0.2); 
}
.outputsTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 50px;
}
.smartGoalDividerPositioning {
  margin-left:1%; 
  margin-bottom:2%;
}

.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}

.subHeaders {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
}

.mainHeaders {
  color: rgb(19, 79, 113);
  font-weight: bold;
  background-color: orange;
}

.dropDownText {
  color: rgb(19, 79, 113);
  font-weight: bold;
  cursor: pointer;
  border-bottom: solid 2px orange;
}

</style>