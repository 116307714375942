import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSnackbar,{staticClass:"d-flex justify-start",staticStyle:{"font-family":"'Poppins', sans-serif","max-width":"100%"},attrs:{"timeout":"100000","multi-line":_vm.multiLine,"color":"white black--text"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c(VRow,{staticClass:"pa-1 d-flex justify-center",staticStyle:{"height":"auto","width":"330px"}},[_c(VCol,{staticClass:"pa-0 d-flex justify-center",staticStyle:{"font-size":"18px"},attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.text))]),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}}),_c(VCol,{staticStyle:{"font-size":"14px"},attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"11"}},[_vm._v(" Subscribe to our Newsletter to get information about upcoming activities and project progress! ")]),_c(VCol,{staticStyle:{"padding":"0px","margin":"0px"},attrs:{"cols":"1"}},[_c(VBtn,{staticStyle:{"padding":"0px","margin":"0px","min-width":"20px","max-height":"20px","background-color":"#ff712e"},on:{"click":function($event){_vm.snackbar= false}}},[_vm._v("X")])],1)],1)],1),_c(VTextField,{attrs:{"label":"E-mail","filled":"","outlined":"","background-color":"black"}}),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{staticClass:"pb-3 d-flex justify-center",staticStyle:{"margin-top":"-14px"},attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" Subscribe ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }