<template>
  <v-row class="mt-0 pa-2" >
    <v-col cols="12" class="mt-15">
      <v-row style="font-family: 'Source Sans Pro', sans-serif;">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-for="(news,index) in newsCards" :key="index" class="pa-5"> 
          <v-dialog transition="dialog-bottom-transition" max-width="1000px">
            <template  v-slot:activator="{ on, attrs }">
              <v-hover v-slot:default="{ hover }">
                <v-card :elevation="hover ? 12 : 4" color="white" v-bind="attrs" v-on="on"> 
                  <v-img  :src="news.mediaurl"  width="auto"  height="273px" contain>  </v-img>
                  <v-card-text  class="bodyFontTypeSize d-flex justify-center " >
                    {{news.title}}
                  </v-card-text>
                </v-card>
              </v-hover>
            </template>
            <template v-slot:default="dialog">
              <v-card class=" black--text ">
                <v-app-bar class=" titleFontTypeSize primary lighten-4 d-flex justify-center"  >
                  <strong> {{news.title}}  </strong>
                </v-app-bar>
                <v-card-text style="margin:0px; padding:0px;" >
                  <v-img  :src="news.mediaurl" aspect-ratio="1.3" black--text height="auto" contain></v-img>
                  <div class="bodyFontTypeSize pa-6" v-html="news.content" ></div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn class="error--text" @click="dialog.value = false"> Lukk </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>


<script>
  export default {
    name: 'News',
    data(){
      return{
        accessKey:window.btoa('bac436b32a36431bb437b9509b6d3495'),
        news: [],
        // Quick n Dirty fix - Just make it work
        newsCards: [
          {
            id: 147,
            order: 1,
            title: "Kick off meeting for RESET project",
            mediaurl: "https://followup-files.s3.eu-north-1.amazonaws.com/d1511683-88a1-4085-ab4b-fad84e9c8fb8",
            content: "<p>31st of May RESET project finally “kicked itself” off. Partners from 5 countries participates and will do its best to improve the digital readiness among European schools.</p><p>Project will work almost simultaneously on 4 outputs during the 2 year project lasts.</p>",
          },
          {
            id: 0,
            order: 2,
            title: "1st TPM in Sofia, Bulgaria",
            mediaurl: "img/TPM_Sofia.jpg",
            content: "<p>REinventing School Education Toward a digital world - RESET 2020-1-NO01-KA226-SCH-094181</p> <br> <p> <span>#digitaltransformation</span><br> <span>#digitalfriendlyschools</span><br> <span>#digitalstrategy</span><br> <span>#outputs</span></p>"
          },
          {
            id: 242,
            order: 3,
            title: "LTTA in Naples, Italy",
            mediaurl: "https://followup-files.s3.eu-north-1.amazonaws.com/5700e5e7-7348-41aa-9933-f869bd445c31",
            content: "<p>Although most of the classrooms are equipped with digital education tools and smart boards are used, does not mean that a digital strategy is implemented!</p><p>Together with Veksttorget, the project coordinator, Verdal High School and Prios from Norway, ValueMe from Bulgaria, EduLab from Italy and Milli Eğitim Bakanlığı from Turkey and Asociatia Edulifelong from Romania has had the pleasure of both sharing our expertise and learning a lot within educational challenges proposed, and to discover new methods and solutions for the use of digital tools in the school environment.</p><p>It was especially interesting to get to work with teachers from 3 schools in Naples in the training.</p><p>The three days of training, 20-22 April 2022, were dedicated to learning about the correct use of technology in education in the context of applying a Digital Strategy in the schools. It has enabled us to test the solutions proposed as project results and to discuss about the development of the \"Digital Friendly School\", European Label.</p><p>A big thank you to the host, Edu Lab, for the great hospitality and organization, and other partners for great contributions.</p><p>The Erasmus Project “REinventing School Education Toward a digital world“ (RESET) will work closely with school management and teachers to investigate and develop solutions easy to implement. RESET is a 24-month project co-funded by the Erasmus+ programme of the European Union, ref no. 2020-1-NO01-KA226-SCH-094181.</p>",
          },
          {
            id: 149,
            order: 4,
            title: "SELFIE for Teachers",
            mediaurl: "https://followup-files.s3.eu-north-1.amazonaws.com/99fc6d0e-b963-4681-b958-51a3467ee198",
            content: "<p>As part of the new <a href=\"https://ec.europa.eu/education/education-in-the-eu/digital-education-action-plan_en\" target=\"__blank\" rel=\"noopener noreferrer nofollow\">Digital Education Action Plan 2021-2027</a>, the Commission is developing a free online tool for primary and secondary teachers. The tool, ‘SELFIE for Teachers’ will allow teachers to reflect on and review how they are using technology in their professional practice and to get suggestions for further development.</p><p>A pilot version of the tool is currently being tested with teachers in Portugal, Estonia, Lithuania, Italy and Ireland. We are gathering feedback from the teachers through focus groups and feedback surveys on what works well and where the tool could be further improved. SELFIE for Teachers will be finalised over the summer and made available in the <a href=\"https://europa.eu/european-union/about-eu/eu-languages_en\" target=\"__blank\" rel=\"noopener noreferrer nofollow\">24 official EU languages</a> in autumn 2021.</p><p>Follow RESET and you will also follow the development of SELFIE for Teachers. </p>",
          },
          {
            id: 148,
            order: 5,
            title: "What competence do teachers need to improve",
            mediaurl: "https://followup-files.s3.eu-north-1.amazonaws.com/82b0a3fd-5acf-4d6b-93b2-cad714a2997e",
            content: "<p><strong>What kind of skills / knowledged o teachers need to improve in order to better developing their own digital materials?</strong></p><p>We have asked more than 300 teachers and will ask even more. So far the conclution is a huge demand to learn more among teachers. More than 65% answered its important for them to improve their skills and competences within all questions asked. Most needed is:</p><p>- Creating adaptive learning material for specific learning goal within the school subjects</p><p>- Use of collaboration tools or programs live while teaching</p><p>- Creating short video-based learning videos</p><p>- Creating hyper documents to increase students' engagement</p><p>- Create quizzes and tasks for competitions</p><p>RESET project will work to develop good tools and learning materials which enable teachers to create their own digital learning material.</p><p>Taka a look at results: <a href=\"https://drive.google.com/file/d/1cTmZ_ahHgOwIzCyr2u6R0_TbOXDSsGCZ/view?usp=sharing\" target=\"__blank\" rel=\"noopener noreferrer nofollow\">https://drive.google.com/file/d/1cTmZ_ahHgOwIzCyr2u6R0_TbOXDSsGCZ/view?usp=sharing</a></p>",
          },
          {
            id: 149,
            order: 6,
            title: "Last Reset meeting",
            mediaurl: "img/last_reset_meeting.jpg",
            content: "<p>Our RESET Project (2020-1-NO01-KA226-SCH-094181) had its regular monthly meeting, where partners had the chance to catch up on the project's latest activities as well as plan ahead for the weeks to come! </p> <p>We are currently finalizing the project results, specifically in elaborating a European Certification for Schools.</p>"
          }
        ]    
      }
    },
    mounted(){
      this.getAllNews();
    },
    methods: {
      getAllNews() {
        this.$http.get('https://app.followup.prios.no/api/news/9b1dfa68-cb03-435f-9669-a52c772d3374/',{headers:{Tempaccess:this.accessKey}} ).then((response) => {
        this.news = response.data;
        console.log(response.data)
        })
      },
    },
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
  .titleFontTypeSize{
    color:black !important;
    font-size:22px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .bodyFontTypeSize{
    color:black !important;
    font-size:18px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  
  </style>