<template>
  <v-row>
    <PartnerDialog ref="ParnerDialog"></PartnerDialog>
    <NewsCardDialog ref="NewsCardDialog"></NewsCardDialog>
    <v-col cols="12">
      <v-row>
        <v-col class="" cols="12">
          <v-card class="placeholderMarginPadding">
            <v-container>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="d-flex justify-center align-center">   
                  <iframe width="700" height="400" src="https://www.youtube.com/embed/bg9VpezmpSk" title="YouTube video player" frameborder="0"   allowfullscreen allow=""><pre></pre></iframe>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class=" bodyFontTypeSize d-flex align-center justify-center" >
                  <template>
                    <v-carousel style="width:800px;" cycle height="250px" hide-delimiter-background show-arrows-on-hover>
                      <v-carousel-item v-for="(slide, i) in slides" :key="i">
                        <v-sheet style="padding: 25px;" :color="colors[i]" height="100%">
                          <v-row class="fill-height" align="center" justify="center">
                            <div class="text-h4" style="text-align:center;">
                              {{ slide }}
                            </div>
                          </v-row>
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <v-col cols="12" style="padding-top: 20px; padding-left:20px padding-right:20px" class="d-flex justify-center align-center">
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"  class="titleFontTypeSize d-flex justify-center align-center" style="margin-bottom:-20px;">
            <p>
              <strong> Project “REinventing School Education Toward a digital world“ (RESET) </strong> will work closely with school management and teachers to investigate and develop solutions easy to implement.
              The European label “Digital Friendly Schools” will be introduced as a certification, which schools can apply to prove their digital educational readiness.
            </p>
          </v-col> 
        </v-col>

        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <!------------------- 3 cards  NEWS-------------------------------------------------------------------------------------------- -->
        <v-col class="d-flex justify-center"  cols="12" style="max-width:100vw;">
          <strong  style="font-size:30px; font-family: 'Poppins', sans-serif; ">News</strong>
        </v-col>
        <v-col v-for="news in news.slice(0,3)" :key="news.mediaurl" cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="d-flex justify-center" style="font-size:30px; font-family: 'Source Sans Pro', sans-serif;">
          <v-hover height="100%" v-slot:default="{ hover }" >
            <v-card style="width:400px; "  v-if="$vuetify.breakpoint.mdAndUp" :elevation="hover ? 12 : 4" :ripple="true" @click.native="$refs.NewsCardDialog.openNewsCard(news)">
              <v-card-title  class="titleFontTypeSize d-flex justify-center">{{ news.title }}</v-card-title>
              <v-img :src=" news.mediaurl" contain height="300px"></v-img>
              <v-card-text> Published: {{news.created}}</v-card-text>
              <v-card-text style="height:80px; font-size:14px; display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; overflow: hidden;"  class="bodyFontTypeSize d-flex justify-end">{{ news.preview }}</v-card-text>
              <v-btn text class="d-flex align-center justify-end" to="/news"> link to article </v-btn>
            </v-card>
            <v-card height="auto" v-else style=" margin-right:5px; margin-left:5px;"
              :elevation="hover ? 12 : 4"
              :ripple="true"
              @click.native="$refs.NewsCardDialog.openNewsCard(news)">
                <v-card-title  class="titleFontTypeSize d-flex justify-center">{{ news.title }}</v-card-title>
                <v-img :src=" news.mediaurl" contain height="300px"></v-img>
                <v-card-text> Published: {{news.created}}</v-card-text>
                <v-btn text class="d-flex justify-end" to="/news"> link to article </v-btn>
            </v-card>
          </v-hover>
        </v-col>

        <v-col cols="12" style="max-width:100vw;">
          <v-divider></v-divider>
        </v-col>

        <!-- 4 partner cards -->
        <v-col cols="12">
          <v-row class="d-flex justify-center align-center" style="margin-left: -20px;">
            <template>
              <v-col style="max-width:100%;" cols="12" class="d-flex justify-center">          
                <h1 style="font-family: 'Poppins', sans-serif;">Partners</h1>
              </v-col>
              <v-col class="d-flex justify-space-around" style="margin:0px; padding:0px;" cols="12" xs="6" sm="auto" md="auto" lg="auto" xl="auto"
              v-for="(partners) in Partners4Cards"
              :key="partners.key">
              <!-- click event that opens the dialog --> 
                <v-hover class="d-flex justify-center"  style=" width:150px; margin:10px;"  v-slot:default="{ hover }">
                  <v-card :ripple="true" :elevation="hover ? 12 : 4" style=" width:176px; padding:5px; margin-right:5px; margin-left:5px; background-color:white;" @click.native="$refs.ParnerDialog.openPartnerDialog(partners)">
                    <v-img :src="partners.src" height="150px" contain ></v-img>
                  </v-card>
                </v-hover>
              </v-col>
            </template>
          </v-row>
        </v-col>

<!-- -------- 3 partner cards---------------------------------------------------- -->

        <!-- <v-col cols="6" xs="6" sm="6" md="4" lg="4" xl="4" 
          class="d-flex justify-center"
          
          v-for="(partners,index) in Partners3Cards"
         :key="index">
         <v-hover   style=" width:300px" v-slot:default="{ hover }">
          <template>
            <v-card  :elevation="hover ? 12 : 4" :ripple="true" style="padding:5px; margin-right:5px; margin-left:5px; width:340px; background-color: white;" @click.native="$refs.ParnerDialog.openPartnerDialog(partners)"          >
              <v-img :src="partners.src" height="250px" contain></v-img>
            </v-card> 
          </template>          
         </v-hover>
        </v-col>         -->
        <!-- <v-col cols="12" class=" placeholderHeight d-flex align-center justify-center">
          Placeholder
        </v-col> -->
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped>
 /* *{outline:auto} */
 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

.titleFontTypeSize{
  color:black !important;
  font-size:22px;
  font-family: 'Source Sans Pro', sans-serif;
}
.bodyFontTypeSize{
  color:black !important; 
  font-size:18px;
  font-family: 'Source Sans Pro', sans-serif;
}

.placeholderHeight{
  height: 100px;
}
.placeholderMarginPadding{
  padding: 0px;
}


</style> 

<script>
import PartnerDialog from '../components/PartnerDialog.vue'
import NewsCardDialog from '../components/NewsCardDialog.vue'
import myDialog from '../components/myDialog.vue'
import Newsletter from '../components/Newsletter.vue'
  export default {
    name:"News",
    mounted(){
      this.getAllNews();
    },
    components:{
    PartnerDialog,
    NewsCardDialog,
    myDialog,
    Newsletter
  },
  // sends data to "NewsCardDialog.vue"
    data(){
      return{
        
        accessKey:window.btoa('bac436b32a36431bb437b9509b6d3495'),
        news: [],       
        Partnerdialog: false,
        Partners4Cards: [
          {key:"4", src:'../img/icons/Veksttorget.png', partnerTitle:'Veksttorget Marked', Link:"https://www.veksttorget.no" ,text:'Veksttorget has over 25 years of experience in helping people at work. The company is a non-profit company - owned by Verdal Municipality - that carries out important community assignments and contribute to increased inclusion and value creation in the workplace.'},
          {key:"5", src:'https://lirp.cdn-website.com/55d85f6d/dms3rep/multi/opt/prioslogo_dark-187w.png', partnerTitle:'Prios', Link:"https://www.prios.no/" , text:'Prios Kompetanse AS (Prios) is a research - based corporation established by several cooperative competence environments. Prios as a research center aims to implement the idea of lifelong learning, support and conducting development projects.'},
          {key:"6", src:'https://trondelag.pameldingssystem.no/auto/139/Verdal_vgs%20-%20450.jpg', partnerTitle:'Verdal Videregående skole', Link:"https://web.trondelagfylke.no/verdal-videregaende-skole" , text:'Verdal upper secondary school is a combined school with a total of about 700 students and 140 employees. The school has five disciplines, which are general studies, four vocational studies in building and construction, engineering and industrial manufacturing, services and transport, health and youth development.'},
          {key:"7", src:'../img/icons/edulab logo.jpg', partnerTitle:'EDULab', Link:"https://edulabnet.it/" , text:'EDU Lab main aim is to give more opportunities to the vulnerable groups of its territory arising their awareness towards many crucial subjects. It is vital for our association to share, transfer, acquiring new ideas in order to build together mutual and conscious actions in the direction of integration, inclusion, reciprocal respect, sustainable economy.'},
          {src:'../img/icons/GENERAL DIRECTORATE PNG Logo.png', partnerTitle:'General Directory Of Secondary Education', Link:"http://ogm.meb.gov.tr/" ,text:'The Ministry of National Education; is an institution responsible for the tasks such as to plan, to schedule, to carry out, to follow and to check all the services of education belonging to the teachers and the students in all grades of education (pre-school, primary, secondary and all formal and non-formal schools).'},
          {src:'../img/icons/valume with icon new.png', partnerTitle:'ValueMe', Link:"https://www.valueme.bg/" , text:'ValueMe is one of the leading institutions for delivering innovative adult education in the means of professional trainings and qualifications in Bulgaria. We promotes an alternative to the actual educational and training system, a different way of understanding the teaching-learning processes, by offering high quality non-formal education and also training to adults (mainly teachers but also youth workers) as well as young people.'},
          {src:'../img/icons/asociatia edulifelong.png', partnerTitle:'Asociatia Edulifelong',Link:"https://asociatiaedulifelong.wixsite.com/asociatiaedulifelong" , text:'Asociația Edulifelong is a non-profit organization founded in 2013. which promotes an alternative to the actual educational and training system, a different way of understanding the teaching-learning processes, by offering high quality non-formal education and also training to adults (mainly teachers but also youth workers) as well as young people.'},
          ],
        Partners3Cards: [
          
        ],
        colors: [
          'white',
          'white',
          'white',
          'white',
          'white',
          
        ],
        slides: [
          'Is your school happy with how distance learning worked during COVID? ',
          'Have your students learned in a good way from home?',
          'Do teachers at your school have the ability to develop digital learning content and use it in a pedagogical way? ',
          'How do you empower students to participate in digital learning environment?',
          'Is it possible for parents, students, teachers and stakeholders to know if your school is a Digital Friendly School?',
          
        ],
      }
    },
    methods: {
      getAllNews(){
        this.$http.get('https://app.followup.prios.no/api/news/9b1dfa68-cb03-435f-9669-a52c772d3374/',{headers:{Tempaccess:this.accessKey}} ). then((response) => {
          this.news = response.data;
          console.log(response.data)
        })
      },
    }
  }
</script>



