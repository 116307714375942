import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import News from '../views/News.vue'
import TrainingModules from '../views/TrainingModules.vue'
import Outputs from '../views/Outputs.vue'
import MappingTool from '../views/mappingTools.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/outputs',
    name: 'Outputs',
    component: Outputs
  },
  {
    path: '/events',
    name: 'TrainingModules',
    component: TrainingModules
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/mappingtool',
    name: 'Mapping tools',
    component: MappingTool
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
