import Vue from 'vue'
import Vuex from 'vuex'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify,  {
  iconfont: 'md',
})

export default new Vuetify({
  
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
  
    theme: {
      themes: {
        light: 
        {
        primary: '#2822a8',
        secondary: '#ff712e',
        accent: '#2196f3',
        error: '#f44336',
        warning: '#ffeb3b',
        info: '#607d8b',
        success: '#4caf50'
        },
      }
    },
  
})

