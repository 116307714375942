<template>
<v-app>


<v-main>
  <About></About>
</v-main>

</v-app>
</template>

<script>
  import About from '../components/About'
  


  export default {
    name: 'Home',

    components: {
      About,
    },
  }
</script>