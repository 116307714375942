import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"mt-0 pa-2"},[_c(VCol,{staticClass:"mt-15",attrs:{"cols":"12"}},[_c(VRow,{staticStyle:{"font-family":"'Source Sans Pro', sans-serif"}},_vm._l((_vm.newsCards),function(news,index){return _c(VCol,{key:index,staticClass:"pa-5",attrs:{"cols":"12","xs":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,_vm._g(_vm._b({attrs:{"elevation":hover ? 12 : 4,"color":"white"}},'v-card',attrs,false),on),[_c(VImg,{attrs:{"src":news.mediaurl,"width":"auto","height":"273px","contain":""}}),_c(VCardText,{staticClass:"bodyFontTypeSize d-flex justify-center"},[_vm._v(" "+_vm._s(news.title)+" ")])],1)]}}],null,true)})]}},{key:"default",fn:function(dialog){return [_c(VCard,{staticClass:"black--text"},[_c(VAppBar,{staticClass:"titleFontTypeSize primary lighten-4 d-flex justify-center"},[_c('strong',[_vm._v(" "+_vm._s(news.title)+" ")])]),_c(VCardText,{staticStyle:{"margin":"0px","padding":"0px"}},[_c(VImg,{attrs:{"src":news.mediaurl,"aspect-ratio":"1.3","black--text":"","height":"auto","contain":""}}),_c('div',{staticClass:"bodyFontTypeSize pa-6",domProps:{"innerHTML":_vm._s(news.content)}})],1),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{staticClass:"error--text",on:{"click":function($event){dialog.value = false}}},[_vm._v(" Lukk ")])],1)],1)]}}],null,true)})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }