<template>
<!-- v-model connects the entire dialog window to an array,
and displays the data it is given from parent and this file. -->
  <v-dialog class="titleFontTypeSize" v-model="Partnerdialog" transition="dialog-bottom-transition" max-width="800px">
    <v-card>
      <v-card-title class="d-flex justify-center" >
        {{ item.partnerTitle }}
      </v-card-title>
      <v-card-text class="bodyFontTypeSize">
        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="4" class="pb-10 pt-5 d-flex justify-center">
            <v-img
              :src="item.src"  
              width="400px"
              height="auto"
              contain>
            </v-img> 
          </v-col>
          <v-col  cols="4"> </v-col>  
        </v-row >
          <!-- v-if="EmptyArrayPopulatedByParentArray . Key: valueInsi deAnObject == 'ValueOfAKey'
          The Array is populated in the script of this file by the parent (home.vue).
          It inserts data into an empty array which populates the popups that is named "ValueOfKey"
          For example "Veksttorget Marked".

          The if statement looks for things called "ValueOfKey" 
          and populates that <div> with the given data.

          " -->
          <div style="padding-bottom:20px;">
            <div v-if=" item.partnerTitle == 'Veksttorget Marked' ">
              Veksttorget has over 25 years of experience in helping people at work. The company is a non-profit company - owned by Verdal Municipality - that carries out important community assignments and contribute to increased inclusion and value creation in the workplace. <br> <br> 
              Veksttorget is an expert partner for Verdal Municipality, supporting both development and implementation of new services and ways to work. <br><br>
              Our focus is on young people who for some reason are out of work or schooling. We use a variety of methods to help the individual in life; including work practices, clarifications, surveys, organized work and competence raising.</div>
            <div v-if=" item.partnerTitle == 'Prios' ">
              Prios Kompetanse AS (Prios) is a research - based corporation established by several cooperative competence environments. <br> Prios as a research center aims to implement the idea of lifelong learning, support and conducting development projects. The center is open to all who wish to develop their talents; refresh their knowledge, improve their business; needs new or better digital tools or have ideas in need of support to be fulfilled.<br><br>
              Our courses and assignments are carried out by own staff or professionals from our large network. <br>  Prios receives no government funding. We recieve our main source of income from sales fees generated by our services.<br><br> The main sectors we operate in is: <br>
                <li>  Adult and youth education. Formal and non - formal education, training and courses.</li>
                <li> Tutor and advisor for innovation processes for startups and well - established companies.</li>
                <li> Software development, tailored solutions for mainly education and SME. </li> <br>We developed and own the Follow - Up® concept
            </div>
            <div v-if=" item.partnerTitle ==  'Verdal Videregående skole' ">
              Verdal upper secondary school is a combined school with a total of about 700 students and 140 employees. <br> The school has five disciplines: 
              <li >General studies.</li>
              <li >Four vocational studies in building and construction.</li>
              <li >Engineering and industrial manufacturing.</li>
              <li >Services and transport.</li>
              <li >Health and youth development.</li> The school also offers a wide variety of extensive courses. <br><br>
              Verdal upper secondary school is organizationally responsible for the exchange of Norwegian students in projects, such as planning, implementation, evaluation and reporting. During the students stay, the school require at least one Norwegian instructor available for students at sight and one person responsible in the school in Verdal.
            </div>
            <div v-if=" item.partnerTitle ==  'EDULab' ">
              EDU Lab main aim is to give better opportunities to the vulnerable groups in its territory; elevating their awareness towards many crucial subjects. <br> It is vital for our association to share, transfer and acquire new ideas in order to build together mutual and conscious actions in the direction of integration; inclusion, reciprocal respect; sustainable economy. <br>
              EDU Lab is an association founded in 2014 by a group of volunteers active for years in the Vesuvius area and involved in the issues of education and social inclusion.<br><br>
              Through European projects, educational workshops, study days and educational activities, we aim to:
              <li>Encourage the participation of weaker segments of the society, with problems of a different nature and kind.</li> 
              <li>Promote the integration of children, teenagers and young people in different contexts, by removing the causes that hinder equal opportunities.</li>
              <li>Strengthen and encourage social inclusion and cultural integration between citizens of different nationalities.</li> 
              
              <br><br>EDU Lab promotes, develops and realizes innovative projects aimed at improving the dialogue between school and territory; spreading practices of informal and non formal education, strengthening the link between education, action and work. <br><br>
              The Association also encourages the implementation of social solidarity actions - in particular the realization of cultural-socio educational initiatives; training-school activities for children and young people, through the promotion and dissemination of disciplines aimed at contributing to their cultural and civil development.<br><br>
              Edu Lab then, through targeted projects aims at stimulating its territorial context in order to increase the opportunities for young people to experience periods abroad, to and from Italy.
            </div>
            <div v-if=" item.partnerTitle ==  'General Directory Of Secondary Education' ">
              The Ministry of National Education is an institution responsible for the tasks such as to plan, schedule, carry out, and to follow and check all the services of education belonging to the teachers and students each level of education (pre-school, primary, secondary and all formal and non-formal schools). <br><br> The Ministry of National Education is an organization that consists of central, provincial, transnational, and relevant organizations. The Directorate General of Secondary Education is one of the departments of The Ministry of Education.<br><br>
              This department runs the tasks and services related to the education and Management of General High Schools, Anatolian High Schools, Science High Schools, Fine Arts High Schools and all similar schools at the same level. <br><br> This department also prepares the schedules, programmes, course books, and the materials and equipment. 
              There are 12506 schools, 371234 teachers and 5649000 students within the secondary education in our country.
            </div>
            <div v-if=" item.partnerTitle ==  'ValueMe' ">
              ValueMe is one of the leading institutions for delivering innovative adult education in the means of professional trainings and qualifications in Bulgaria. 
              <br><br> We promotes an alternative to the actual educational and training system - a different way of understanding the teaching-learning processes, by offering high quality non-formal education and also training to adults (mainly teachers but also youth workers) as well as young people. 
              <br><br> We have a serious team of facilitators, trainers and educators and the right range of participants, thanks to our wide local and national network. <br> Our activities cover the three main areas: non-formal education, education for personal development and professional training. 
              <br><br> ValueMe’s mission is to create and support the effective use of smart and contemporary practices in the field of education and training. The strategic vision of the company is related to education and technologies; tools and applications, educational working solutions, learning resources and innovative teaching models. 
              <br><br> ValueMe believes in the benefits of learning from each other and sharing expertise.
              <br><br> We actively participate in European innovation projects to develop ourselves and offer our customers up to date methods, tools and knowledge. ValueMe is an internationally certified training institution. 
              <br><br>This means that our offer is of high quality at all stages of training. Our certified teachers offer courses to teachers who want to improve their educational competence. This involves - among other things, using digital tools in a constructive way.
            </div>
            <div v-if=" item.partnerTitle ==  'Asociatia Edulifelong' ">
              Asociația Edulifelong is a non-profit organization founded in 2013, which promotes an alternative to the formal educational and training system, a different way of understanding the teaching-learning processes. <br> <br> 
              We do this by offering high quality non-formal education and also training to adults (mainly teachers but also youth workers) as well as young people. We have a serious team of facilitators, trainers and educators and the right range of participants, thanks to our wide local and national partnerships involving, schools, universities and rural communities and municipalities. 
              <br><br>Our activities cover the three main areas: non-formal education, education for personal development and professional training. <br> <br> 
              Through the planned projects and partnerships, our association aims at involving young people and youth workers - with the help of non-formal and formal education methods - in activities at local, regional or transnational levels. <br> <br>
               In order to achieve our goals we intend to organize activities and events such as: meetings, workshops, training courses, seminars, conferences, street events, festivals, youth exchanges etc.
            </div>
          </div>
        <v-row>
          <v-col class="d-flex justify-center" cols="12">
            <v-row wrap>
              <v-col class="d-flex justify-center" cols="12">
                <v-btn width="280px" class="bodyFontTypeSize" :href="item.Link" target="_blank">
                  Check out their Website
                </v-btn> 
              </v-col>
              <v-col class="d-flex justify-center" cols="12"><v-btn style="" @click="closePartnerDialog()" color="error">Close</v-btn></v-col>
            </v-row>
          
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

.liMarginBottom{
  margin-bottom: -20px;
}

.titleFontTypeSize{
  color:black !important;
  font-size:22px;
  font-family: 'Source Sans Pro', sans-serif;
}
.bodyFontTypeSize{
  color:black !important; 
  font-size:18px;
  font-family: 'Source Sans Pro', sans-serif;
}
</style>

<script>

  export default {
/*Item is an empty array (item:[]) where the parent (home.vue) 
inserts data into the empty array. */    
    data(){
      return{
        Partnerdialog : false,
        item:[],
      }
    },

    /*
    *item is given all the data from the parent array.
    
    *item is named "partnerInfo".
    
    *openPartnerDialog opens the dialog in parent and populates the dialog
    with data from this file + parents data.
       */
    methods:{
      openPartnerDialog(partnerInfo){
        this.Partnerdialog = true;
        this.item = partnerInfo;
      },
      closePartnerDialog(){
        this.Partnerdialog = false
      },
    },
  }
</script>
