<template >

  <v-footer color="#FFFFFF" elevation="10">
    <ContactForm  ref="ContactFormButton"></ContactForm>
    <v-row class="footerHeight" style="margin-right:-24px;">

              
      <v-col cols="12" style="display:flex; justify-content:flex-end; position:absolute; bottom:0; right: 0;" ><a style="width:40px;" target="_blank" href="https://www.facebook.com"><v-img  contain height="36px" src="../assets/facebook-circular-logo.png"></v-img></a></v-col>

      <v-col style="text-align:center;" cols="12" xs="12" sm="12" md="12" lg="12" xl="12"  class="d-flex align-center justify-center primary lighten-4 footerHeight">
        <v-row>
          <v-col cols="12" xs="12" sm="4" md="2" lg="2" xl="2"><img height="60px" contain src='../../public/img/icons/erasmus.png' alt=""></v-col>
          <v-col cols="12" xs="12" sm="8" md="10" lg="10" xl="10">
            Disclaimer: This project has been funded with the support from the European Commission (project no: 2020-1-NO01-KA226-SCH-094181). <br> 
        This publication reflects the views only of the author, and the Commission cannot be held responsible for any use which may be made of the information contained therein.
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="$vuetify.breakpoint.mdAndUp" style="background-color:#A785FF;">
        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="d-flex justify-center">
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1" class="d-flex justify-space-around" >
            <v-img contain height="30px" src="../assets/Veksttorget.png"></v-img>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1" class="d-flex justify-space-around" >
            <v-img contain height="30px" src="https://lirp.cdn-website.com/55d85f6d/dms3rep/multi/opt/prioslogo_dark-187w.png"></v-img>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1" class="d-flex justify-space-around" >
            <v-img contain height="30px" src="https://trondelag.pameldingssystem.no/auto/139/Verdal_vgs%20-%20450.jpg"></v-img>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1" class="d-flex justify-space-around" >
            <v-img contain height="30px" src="../assets/edulab logo.jpg"></v-img>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1" class="d-flex justify-space-around" >
            <v-img contain height="30px" src="../assets/GENERAL DIRECTORATE PNG Logo.png"></v-img>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1" class="d-flex justify-space-around" >
            <v-img contain height="30px" src="../assets/valume with icon new.png"></v-img>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1" class="d-flex justify-space-around" >
            <v-img contain height="30px" src="../assets/original-logos-2014-Jan-7216-649364.png"></v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12" style="height:40px;background-color:#A785FF;"></v-col>
    </v-row>
  </v-footer>
</template>

<style scoped>
.footerHeight{
  height:auto;
}
.footerMargin{
  margin: 0px;
}


</style>

<script>
import ContactForm from '../components/ContactForm.vue'
export default {
  components:{
    ContactForm,
  },
  data(){
    return{

    }
  },
}
</script>