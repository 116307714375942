<template>
  <!-- label describes the data that goes inside "label="First Name"
    Hint is rather selfexplanatory.
    You connect everything with v-models and connects them in the data(){return{DataHere}} -->
  <v-dialog  v-model="ContactForm">
    <v-card >
      <v-card-title>
        <span >Contact Form</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col class="pa-0" cols="12">
                  <v-text-field
                    label="Name"
                    required 
                    v-model="name"                   
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12">
                  <v-row>
                    <v-col cols="6" >
                      <v-text-field class="pa-0"
                        label="E-mail"
                        required
                        v-model="email">
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" >
                      <v-text-field class="pa-0"
                        label="Phone"
                        required
                        v-model="phone"> 
                      </v-text-field>
                    </v-col>
                  </v-row>
                <v-col class="pa-0" cols="12">
                <v-text-field v-model="title" label="Subject" required></v-text-field>
                </v-col>
                </v-col>
                <v-col class="pa-0" cols="12" >
                  <v-textarea
                    label="Message"
                    required
                    scrollable
                    v-model="message"
                    hide-details>
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn color="error darken-1 white--text"  title="Lukker skjema" @click="closeDialog()">
                      Close
                  </v-btn>
                  </v-col>
                    
                    <v-spacer></v-spacer>

                    <v-col class="d-flex flex-row-reverse">

                      <!-- sendData sends the data as well as an emit that opens the snackbar in the parent file (NavBar) -->
                      <v-btn class="success" title="Sender melding, og lukker skjema."   @click="sendData()" >
                        Send
                      </v-btn>
                    </v-col>
                </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
/* *{outline:auto} */
</style>

<script>
  export default {
    data(){
      return{
        accessKey:window.btoa('bac436b32a36431bb437b9509b6d3495'),
        item: {},
        ContactForm : false,
        name:"",
        email:"",
        phone:"",
        message:"",
        title:"",
      }
    },

    methods: {
      openDialog() {
        
        this.ContactForm = true;
      },
      closeDialog(){
        this.ContactForm = false;
        this.name=""
        this.email=""
        this.phone=""
        this.message=""
        this.title=""
      },
      sendData(){
        const mySubmit = {
          name : this.name,
          subject: this.title,
          email : this.email,
          phone : this.phone,
          message : this.message,
          sentfrom: "Reset"
        }
        console.log(mySubmit);
        this.$http.post('https://app.followup.prios.no/api/crm/contactform/prios', mySubmit, {headers:{Tempaccess:this.accessKey}}).then(() => {
        console.log("my submit: ", mySubmit);
        this.closeDialog()
      })     
      },
    },
    
    
  }
</script>