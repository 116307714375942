<template>
  <v-card class="ma-0" flat>
    <v-col cols="12">
      <p class="title text-center pb-5 pt-5 ml-3 mr-3 mainHeaders">Resources</p>
      <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px;"></v-divider>

      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
        <v-row>
          <v-col v-for="(item, index) in resourceFolders" :Key="index" cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <v-card @click="displayResource(item.template)">
              <p class="text-center title pt-10 pb-10">{{item.title}}</p>
              <v-divider class="newsCardDividerPositioning" width="98%" style="padding: 2px;" :style="`background-color:${colorArr[index]}`"></v-divider>
              <v-col cols="12" class="ma-0 pa-0" style="padding-top: 0.1px !important;"></v-col>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="mt-10"></v-divider>
        <v-divider></v-divider>
        <v-divider></v-divider>
      </v-col>

      <!-- Default -->
      <v-col v-if="templateChosen == 0">
        <p>No Template are chosen</p>
      </v-col>

      <!-- Images -->
      <v-col v-if="templateChosen == 1">
        <p>Video</p>
        <v-row>
          <v-col class="ma-0 pa-0" v-for="(video, videoIndex) in videoList" :key="videoIndex">
            <iframe :src="video.videoURL" id="videoplayer" width="560" height="315" :title="video.videoTitle" frameborder="0" scrolling="no" webkitAllowFullScreen="true" mozallowfullscreen="true" allowFullScreen="true"></iframe>
          </v-col>
        </v-row>
      </v-col>

      <!-- Files -->
      <v-col v-if="templateChosen == 2">
        <p>Files</p>
        <v-row>
          <v-col cols="12" class="ma-0 pa-0" v-for="(file, fileIndex) in fileList" :key="fileIndex">
            <a :href="file.fileURL" target="_blank">
              <p class="pl-5">{{file.fileTitle}}</p>
            </a>
          </v-col>
        </v-row>
      </v-col>

      <!-- External Files -->
      <v-col v-if="templateChosen == 3">
        <p>External Files:</p>
        <v-row>
          <v-col cols="12" class="ma-0 pa-0" v-for="(external, externalIndex) in externalList" :key="externalIndex">
            <a :href="external.externalURL" target="_blank">
              <p class="pl-5">{{external.externalTitle}}</p>
            </a>
          </v-col>
        </v-row>
      </v-col>

    </v-col>
  </v-card> 
</template>

<script>
export default {
  data(){
    return {
      colorArr:[ "#205072", "#329D9C", "#D83636", "#DD9A30", "#205072", "#329D9C" ],
      resourceFolders:[
        { title: "Video", colour: "", image: "", description: "", template: 1 },
        { title: "Files", colour: "", image: "", description: "", template: 2 },
        { title: "External Links", colour: "", image: "", description: "", template: 3 }
      ],
      templateChosen: 0,
      videoList: [
        {
          videoURL: "https://audiovisual.ec.europa.eu/embed/index.html?ref=I-213776&lg=EN",
          videoTitle: "SELFIEforTEACHERS - A self-reflection tool to support teachers’ digital competence",
          videoDescription: ""
        },
        {
          videoURL: "https://audiovisual.ec.europa.eu/embed/index.html?ref=I-213779&lg=EN",
          videoTitle: "Insights on using SELFIEforTEACHERS",
          videoDescription: ""
        },
        {
          videoURL: "https://www.youtube.com/embed/ckzlnXFncNo",
          videoTitle: "Salmon 5-stage model (by Professor Gilly Salmon) [4K]",
          videoDescription: ""
        },
        {
          videoURL: "https://www.youtube.com/embed/_owun0U-VLA",
          videoTitle: "Design and Delivery for Online Learning, Gilly Salmon",
          videoDescription: ""
        },
        {
          videoURL: "https://audiovisual.ec.europa.eu/embed/index.html?ref=I-201854&lg=EN/EN",
          videoTitle: "European Framework for the Digital Competence of Educators Teaching in the digital age - using technology to enhance learning",
          videoDescription: ""
        },
      ],
      externalList: [
        {
          externalURL: "https://joint-research-centre.ec.europa.eu/digcompedu_en",
          externalTitle: "Digital Competence Framework for Educators (DigCompEdu)",
          externalDescription: ""
        }
      ],
      fileList: [
        {
          fileURL: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/d6c2e9e6-c11d-4676-a2bc-fd80f115a64e.pdf",
          fileTitle: "JCR science for policy report",
          fileDescription: "",
          fileImage: ""
        },
        {
          fileURL: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/7e7b7007-7d12-40b5-aab5-c5afff9ee500.pdf",
          fileTitle: "Credible steps model on how to create a tutorial video",
          fileDescription: "",
          fileImage: ""
        },
      ]
    }
  },
  methods: {
    displayResource(template){
      this.templateChosen = template;
    }
  }
}
</script>

<style scoped>
.smartGoalDividerPositioning {
  margin-left:1%; 
  margin-bottom:2%;
}

.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}

.subHeaders {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
}

.mainHeaders {
  color: rgb(19, 79, 113);
  font-weight: bold;
  background-color: orange;
}

.dropDownText {
  color: purple;
  font-weight: bold;
  cursor: pointer;
}
</style>