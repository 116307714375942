<template>
  <v-card class="ma-0" flat>
   <v-row>
     <v-col cols="12" class="ma-0 pa-0 pl-3 pr-3">
       <p class="title text-center pb-5 pt-5 mainHeaders">Introduction</p>
       <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px;"></v-divider>
     </v-col>
 
     <v-col cols="12">
       <p class="text-center title pb-5 pt-5 mb-5 subHeaders">Practical knowledge</p>
       <p> Through the widespread use of the internet and mobiles, the world has seen changes in traditional life and has already adapted to digital systems and education.</p>
       <p>Studying has approached new variations.</p>
       <p>Switching from live education to distance learning has caused stress not only to students, but to teachers as well.</p>
       <p>That’s why inventing new ways of communicating in education, via online sources and digital classes, is an important step towards the future of the world of education. </p>
       <br>
       <p>The RESET project intends to create innovative ways of communicating and studying.</p>
       <p>The main goal is creating content that is going to be in help of schools and teachers.</p>
       <p>The final package needs to incorporate methods and tools for digital pedagogical competencies for the teachers but also, it’s important that it provides ways of connecting the schools that apply the guidelines developed inside the project. </p>
       <br>
       <p>To develop such a product that is going to be useful, understandable and applicable in practice, it is important to understand the needs of the teachers who have already faced the distance studying.</p>
       <p>That’s why this manual for digital pedagogy for teachers is oriented towards a few important points of online education. </p>
       <br>
       <span class="dropDownText pt-5pb-1" @click="toggleIntroText()">Firstly, it is important to face the advantages but also, the difficulties of distance studying. </span>
       <span v-if="isDisplayed == false">⬇️</span>
       <span v-else>⬆️</span>
       <div v-if="isDisplayed">
       <p>Motivating and engaging students through the computer screen is not an easy task.</p>
         <p>There are techniques with which teachers can empower learners to learn online.</p>
         <p>The manual also provides ideas for the creation of interactive online materials for studying. </p>
         <br>
         <p>It is not only the motivation of students that needs to be covered.</p>
         <p> One of the most important points of digital learning are the digital skills of teachers.</p>
         <p>Practical understanding of the ways to create an online lesson and using open educational resources is a considerable ability for the successful conducting of an online class. </p>
         <br>
         <p>This equipment also faces the need of valuable education tools as a technique to create an educational environment online.</p>
         <p>Digital learning cuts off a lot of the nonverbal communication needed to include everyone in the studying process and to make the lesson interesting and valuable for the learners.</p>
         <p>That’s why we need tools to create an online learning situation and to make the digital content attractive. </p>
         <br>
         <p>Speaking of digital content, the manual includes a variety of good practices from the countries participating in the project.</p>
         <p> Tutorial videos, digital lessons, tests and many more examples of how to make a lesson memorable and valuable for students.</p>
         <p>It is important not only to teach with a digital class but also, to include everyone in the process.</p>
         <p>That’s why monitoring projects online or creating portfolios is needed to keep the creative side of the studying process.</p>
         <p>Different countries share their experience with useful tools and examples.</p>
         <br>
         <p>As long as the digital world continues to expand, it is going to provide new development opportunities.</p>
         <p>That’s why this equipment provides tools and practices on how to benefit in the most effective way of digital education. </p>
       </div>
 
     </v-col>
   </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isDisplayed: false
    }
  },
  methods: {
    toggleIntroText(){
      if(this.isDisplayed == false) {
        this.isDisplayed = true;
      } else {
        this.isDisplayed = false;
      }
    }
  }
}
</script>

<style scoped>.closeButtonStyling {
  position:fixed; 
  top:30px; 
  right:30px; 
  z-index: 5; 
  border:1px solid black; 
  background-color:rgba(255, 0, 0, 0.2);
}
.returningToTop {
  position:fixed; 
  bottom:30px; 
  right:30px; 
  z-index: 5;
  border:1px solid black; 
  background-color:rgba(255, 0, 0, 0.2); 
}
.outputsTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 50px;
}
.smartGoalDividerPositioning {
  margin-left:1%; 
  margin-bottom:2%;
}

p {
  margin-bottom: 0px;
}

.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}

.subHeaders {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
}

.mainHeaders {
  color: rgb(19, 79, 113);
  font-weight: bold;
  background-color: orange;
}

.dropDownText {
  color: orange;
  font-weight: bold;
  cursor: pointer;
  border-bottom: solid 2px black;
  font-size: 22px;
}
</style>