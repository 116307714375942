<template>
  <div class="container">
    <p class="title text-center mt-10 mb-10">Mapping tool</p>
    <p>
      Here you will find the mapping tool to measure your school's digital
      capabilities from the point of view of the main sections identified by the
      project (see the Strategy Manual). The mapping tool has the form of a
      questionnaire that can lead to obtaining the "Digital Friendly School"
      label.
    </p>
    <p class="mb-0">
      Please, be aware that, after clicking on “open mapping tools”, on the
      preferred language banner, you can:
    </p>
    <ol>
      <li>fill in all the required data and click on "take mapping tool".</li>
      <li>
        start answering the questions of the questionnaire divided into the six
        main sections of the Strategy Manual
      </li>
      <li>
        save the answers with the button at the bottom and then later retrieve
        the questionnaire and complete it
      </li>
      <li>
        once concluded, send with the "Submit (Crucial)" button always at the
        bottom
      </li>
    </ol>
    <p class="pt-3 mb-0">
      Please note that once all the questions have been answered, the school
      will receive an email with the final result (in pdf format) with the
      specified score (the mapping) for each sector:
    </p>
    <ol>
      <li>
        In case of a positive result, the Digital Friendly School label will be
        assigned
      </li>
      <li>
        in the event of a negative result, the school will be invited at
        improving its weaknesses and to repeat the test after, at least, six
        months
      </li>
    </ol>
    <p class="pt-3">
      Further details are reported in the booklet “Digital Friendly School -
      Instructions for the Label” downloadable, in your preferred language,
      <br />
      here:
      <a href="https://reset-project.eu/outputs"
        >https://reset-project.eu/outputs</a
      >
      > in the IO4 section
    </p>
    <v-row>
      <v-col cols="12"></v-col>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
        v-for="(card, index) in mappingCards"
        :key="index"
      >
        <v-card style="background-color: rgb(241, 237, 239)">
          <v-card-title>
            {{ card.title }}
            <img class="ml-2" :src="card.flag" width="30px" />
          </v-card-title>
          <v-card-text>
            {{ card.description }}
          </v-card-text>
          <v-card-actions>
            <v-btn @click="$refs.openMappingDialog.openingMappingForm(card.id)">
              Open Mapping Tool
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12"></v-col>
    </v-row>

    <MappingToolMainComponent ref="openMappingDialog" />
  </div>
</template>

<script>
import MappingToolMainComponent from "@/components/MappingTool/MappingToolMainComponent.vue";

export default {
  components: {
    MappingToolMainComponent,
  },
  data() {
    return {
      mappingCards: [
        {
          title: "Digital Friendly Label English",
          description: "Digital Friendly Label English",
          id: 187,
          flag: "/flags/england.png",
        },
        {
          title: "Digital Friendly Label Italian",
          description: "Digital Friendly Label Italian",
          id: 192,
          flag: "/flags/italy.png",
        },
        {
          title: "Digital Friendly Label Romania",
          description: "Digital Friendly Label Romania",
          id: 198,
          flag: "/flags/RomaniaSmall.png",
        },
        {
          title: "Digital Friendly Label Norway",
          description: "Digital Friendly Label Norway",
          id: 202,
          flag: "/flags/norway.png",
        },
        {
          title: "Digital Friendly Label Bulgaria",
          description: "Digital Friendly Label Bulgaria",
          id: 199,
          flag: "/flags/Bulgaria2.png",
        },
      ],
    };
  },
};
</script>
