<template>
  <v-container>
    <SmartGoalDialog ref="openSmartDialog"></SmartGoalDialog>
    <v-row>
      <v-col cols="12" class="mt-15"></v-col>
      <!-- Expandables -->
      <v-col ols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <v-expansion-panels style="width: 100%">
          <v-expansion-panel style="width: 100%">
            <v-expansion-panel-header style="width: 100%">
              <v-card-title
                >IO1 Strategy Mangual for Digital Schools</v-card-title
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              The sudden switch to distance learning in March forced
              schools/educators/students to improvise and use whatever they had
              access to. This “experience” gave a wide range of solutions
              tested. Some successful, some failures. <br /><br />
              What are the criteria for schools which lead to a successful
              transformation to digital learning? Which pitfalls should be
              avoided, what is best practice regarding evaluating/ assessing
              students in an online environment? <br /><br />
              This output will create a strategy, consisting of methods, tools,
              guidelines and checklists within digital readiness competences
              among educators and schools as an organization.

              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0 pt-5">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/7ce5a5e2-7e78-4148-9c07-0bec622bee51.jpg"
                    target="_blank"
                  >
                    <v-img
                      class="mb-0 pb-0"
                      height="300px"
                      width="auto"
                      contain
                      src="../assets/io1image.jpg"
                    ></v-img>
                  </a>
                </v-col>
                <v-col cols="12"></v-col>
                <v-col cols="4">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/3a864e58-a77b-45ee-aca9-ad7df764f8e1.pdf"
                    target="_blank"
                  >
                    <v-row>
                      <v-col cols="7" class="ma-0 pa-0 mr-2">
                        <p class="mt-0 pt-0 linkStyling pt-3">English pdf</p>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-img
                          src="../assets/flags/england.png"
                          height="25px"
                          contain
                          class="mt-3"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </a>
                </v-col>
                <v-col cols="4">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/a495afd9-68fb-47ae-b95e-347cf3f6553b.pdf"
                    target="_blank"
                  >
                    <v-row>
                      <v-col cols="8" class="ma-0 pa-0 mr-2">
                        <p class="mt-0 pt-0 linkStyling pt-3">Norwegian pdf</p>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-img
                          src="../assets/flags/norway.png"
                          height="20px"
                          contain
                          class="mt-4"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </a>
                </v-col>
                <v-col cols="4">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/4e7ab884-49a1-4619-a551-792aad4f7d6e.pdf"
                    target="_blank"
                  >
                    <v-row>
                      <v-col cols="7" class="ma-0 pa-0 mr-2">
                        <p class="mt-0 pt-0 linkStyling pt-3">Bulgarian pdf</p>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-img
                          src="../assets/flags/Bulgaria.png"
                          height="25px"
                          contain
                          class="mt-3"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </a>
                </v-col>
                <v-col cols="4">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/f886473d-385b-40af-93e9-cf58da57c955.pdf"
                    target="_blank"
                  >
                    <v-row>
                      <v-col cols="7" class="ma-0 pa-0 mr-2">
                        <p class="mt-0 pt-0 linkStyling pt-3">Romanian pdf</p>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-img
                          src="../assets/flags/Romania.png"
                          height="25px"
                          contain
                          class="mt-3"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </a>
                </v-col>
                <v-col cols="4">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/63856e7f-6053-415c-bded-28061469cba7.pdf"
                    target="_blank"
                  >
                    <v-row>
                      <v-col cols="5" class="ma-0 pa-0 mr-2">
                        <p class="mt-0 pt-0 linkStyling pt-3">Italian pdf</p>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-img
                          src="../assets/flags/italy.png"
                          height="50px"
                          contain
                        ></v-img>
                      </v-col>
                    </v-row>
                  </a>
                </v-col>
                <v-col cols="4">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/5886db12-b876-4ef4-8c89-4abf4eafb45a.pdf"
                    target="_blank"
                  >
                    <v-row>
                      <v-col cols="6" class="ma-0 pa-0 mr-1">
                        <p class="mt-0 pt-0 linkStyling pt-3">Turkish pdf</p>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-img
                          src="../assets/flags/Turkey.png"
                          height="50px"
                          contain
                        ></v-img>
                      </v-col>
                    </v-row>
                  </a>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-card
            style="width: 100%; min-height: 100px; cursor: pointer"
            @click="$refs.openSmartDialog.openDialog(1)"
          >
            <v-card-title class="pt-9 pl-10"
              >IO2 Digital Equipment for teachers</v-card-title
            >
          </v-card>

          <!-- <v-expansion-panel>
            <v-expansion-panel-header >
              <v-card-title>IO2 Digital Equipment for teachers</v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              An important part of good digital educational readiness is easy access to knowledge about and usage of different digital tools and educational resources among both school as
              organization and all teaching staff.  The usage of different digital tools is directly related to the research and implementation of structured developmental processes.<br><br> To be successful
              learning and teaching online requires several steps. The project will create knowledge and develop guidelines about ways of supporting teachers to create and implement digital
              content. <br> Within the project we will create a pedagogical model about how teachers can provide a positive digital environment and develop guidelines for practical use of open educational resources and equip teachers with digital skills of developing their own digital materials.
              <SmartGoals></SmartGoals>
            </v-expansion-panel-content>
          </v-expansion-panel> -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-title>IO3 E-Communication tool</v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="ma-0 pa-0">
                <v-col cols="12" style="background-color: #e3f2fd">
                  The sudden switch to distance learning showed a lack of good
                  solutions for communications between school/teachers and
                  families/home. There is a need for easy to use solution, which
                  matches the economical limitation most school have in their
                  budget.
                </v-col>
                <v-col cols="12" style="background-color: #fff3e0">
                  The project will develop a communication platform which
                  require a minimum of digital skills to implement by both
                  schools, teachers, students and parents. The platform will
                  offer information of ongoing activities, schedules, messages,
                  tools for chatting and video meeting as well as links to
                  digital lessons, learning material, record attendance and
                  share teachers evaluation.
                </v-col>
                <v-col cols="12" style="background-color: #e8f5e9">
                  A prototype is made as part of project preparations in order
                  to find the right approach which meet the needs. The work in
                  this output will be conducted by expert partners in
                  collaboration with associated partner schools, in order to
                  create knowledge for how such cooperation may work in
                  beneficial ways for all as well as enabling implementation of
                  developed content and tool.
                </v-col>
                <ContactForm ref="ContactFormButton"></ContactForm>
                <v-col cols="12" style="background-color: #e6d4d8">
                  To register your school,
                  <a @click="$refs.ContactFormButton.openDialog()"
                    >contact us</a
                  >
                </v-col>
                <v-col cols="12">
                  <a
                    href="https://creative-llama-997ee8.netlify.app/"
                    target="_blank"
                    style="pointer: cursor"
                  >
                    <p
                      class="mt-0 pt-0 linkStyling pt-3"
                      style="font-weight: bold; font-size: 16px"
                    >
                      Go to the platform
                    </p>
                  </a>
                  <p>
                    <span>You can test the platform with user:</span>
                    <br />
                    <span style="margin-left: 30px">
                      •<strong> Username:</strong>
                      test@prios.com
                    </span>
                    <br />
                    <span style="margin-left: 30px">
                      •<strong> Password:</strong>
                      tester
                    </span>
                  </p>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-title>IO4 Digital Friendly Schools</v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Develop an European Certification for the Schools
              All new ideas, tools, methods etc. will concur at the definition of the process a school has to put in place for RESETting its Didactic approach in a digital setting.
              The creation of the certification for being recognized as a “Digital Friendly School”, wants to be a stimulus for the Schools and also a reason for promoting the school itself in its
              context. <br> <br>

              The achievements of the three former outputs will be used for the definition of a set of requirements and steps for acquiring this certification.
              It will be also important to give a visual identity to a “Digital Friendly School” by defining an attractive label to be used in all the dissemination channels and materials of the school.
              Several meetings and seminars will be organized between the associated partners in order to test and get feedback for adjusting the information to be given for acquiring the
              certification. -->
              <p class="title" style="font-weight: bold">
                Is your School Digital Friendly? <br />
                The European Label of the RESET project
              </p>

              <p>
                The achievements of the three former outputs has been used for
                the definition of a set of requirements and steps for acquiring
                the label of “Digital Friendly School”.
              </p>
              <v-img
                contain
                height="auto"
                src="../assets/digitalfriendlyschool.png"
                width="200"
                class="mx-auto"
              ></v-img>
              <p>
                All new ideas, tools, methods etc. have concurred at the
                definition of the process a school has to put in place for
                RESETting its Didactic approach using Digital facilities.
              </p>
              <p>
                To obtain this label, the school will be asked to answer a
                questionnaire which constitutes a mapping tool of its digital
                capabilities from the point of view of the main sections
                identified by the project.
              </p>
              <p>
                The questionnaire consists of 76 questions divided into 6
                sections. To each answer has been given a weight. If the school
                has reached at least the 75% of the total possible score, it
                will acquire the label. This percentage is an average therefore
                75% can be well exceeded in one and not in another section.
                However, in any case, the school will receive the feedback of
                the percentages reached for each section in order to be informed
                of the possible weaknesses by category if there are any (e.g.
                ICT 70%, management 90%: alert for ICT but the label is
                assigned). All the details are reported in the booklet Digital
                Friendly School.
              </p>
              <p>
                The school will then receive a certificate and the logo to be
                used in its communication channels.
              </p>

              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 pt-5">
                  <!-- <a href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/eeb42e63-4c84-4d6f-81ea-60290e9b9e3f.pdf" target="_blank">
                    <v-img class="mb-0 pb-0"  height="200px" src="../assets/strategyManualCardImg.png"></v-img>
                  </a> -->
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/eeb42e63-4c84-4d6f-81ea-60290e9b9e3f.pdf"
                    target="_blank"
                  >
                    <v-img
                      contain
                      height="auto"
                      src="../assets/Certificate.png"
                      width="auto"
                      class="mx-10"
                    ></v-img>
                  </a>
                </v-col>
                <v-col cols="12"></v-col>
                <v-col cols="12" class="mb-0 pb-0">
                  <p>
                    The label “Digital Friendly School” wants to be a stimulus
                    for the Schools and also a reason for promoting the school
                    itself in its context. The partnership will continue in
                    asking for the recognition of this label from the National
                    Education Boards!
                  </p>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                  <span>Start applying for the Label: </span>
                  <a href="https://reset-project.eu/mappingtool">
                    <span class="mt-0 pt-0 linkStyling">Mapping tools</span>
                  </a>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <p>Download the booklet “Digital Friendly School</p>
                </v-col>
                <v-col cols="12"></v-col>
                <v-col cols="4">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/f47645cd-9646-480e-9760-fc02f7642a96.pdf"
                    target="_blank"
                  >
                    <v-row>
                      <v-col cols="7" class="ma-0 pa-0 mr-2">
                        <p class="mt-0 pt-0 linkStyling pt-3">English pdf</p>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-img
                          src="../assets/flags/england.png"
                          height="25px"
                          contain
                          class="mt-3"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </a>
                </v-col>
                <v-col cols="4">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/5cbd371a-14ee-4a7a-9918-cad24f92c9a1.pdf"
                    target="_blank"
                  >
                    <v-row>
                      <v-col cols="8" class="ma-0 pa-0 mr-2">
                        <p class="mt-0 pt-0 linkStyling pt-3">Norwegian pdf</p>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-img
                          src="../assets/flags/norway.png"
                          height="20px"
                          contain
                          class="mt-4"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </a>
                </v-col>
                <v-col cols="4">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/13f0b440-2a28-4cf8-801b-a45bcff6e67b.pdf"
                    target="_blank"
                  >
                    <v-row>
                      <v-col cols="7" class="ma-0 pa-0 mr-2">
                        <p class="mt-0 pt-0 linkStyling pt-3">Bulgarian pdf</p>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-img
                          src="../assets/flags/Bulgaria.png"
                          height="25px"
                          contain
                          class="mt-3"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </a>
                </v-col>
                <v-col cols="4">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/d8de7ccd-c676-4538-87da-7b7bb7c5ac60.pdf"
                    target="_blank"
                  >
                    <v-row>
                      <v-col cols="7" class="ma-0 pa-0 mr-2">
                        <p class="mt-0 pt-0 linkStyling pt-3">Romanian pdf</p>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-img
                          src="../assets/flags/Romania.png"
                          height="25px"
                          contain
                          class="mt-3"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </a>
                </v-col>
                <v-col cols="4">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/54de3314-880b-49a0-bc07-8dd98e33514d.pdf"
                    target="_blank"
                  >
                    <v-row>
                      <v-col cols="5" class="ma-0 pa-0 mr-2">
                        <p class="mt-0 pt-0 linkStyling pt-3">Italian pdf</p>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-img
                          src="../assets/flags/italy.png"
                          height="50px"
                          contain
                        ></v-img>
                      </v-col>
                    </v-row>
                  </a>
                </v-col>
                <v-col cols="4">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/ecd0a7bb-aa69-4bf8-8dbd-4997fb457285.pdf"
                    target="_blank"
                  >
                    <v-row>
                      <v-col cols="6" class="ma-0 pa-0 mr-1">
                        <p class="mt-0 pt-0 linkStyling pt-3">Turkish pdf</p>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-img
                          src="../assets/flags/Turkey.png"
                          height="50px"
                          contain
                        ></v-img>
                      </v-col>
                    </v-row>
                  </a>
                </v-col>
                <!-- <v-col cols="12" class="mt-0 pt-0">
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/f47645cd-9646-480e-9760-fc02f7642a96.pdf"
                    target="_blank"
                    class="pr-15"
                  >
                    <span class="mt-0 pt-0 linkStyling">English pdf</span>
                  </a>
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/13f0b440-2a28-4cf8-801b-a45bcff6e67b.pdf"
                    target="_blank"
                    class="pr-15"
                  >
                    <span class="mt-0 pt-0 linkStyling">Bulgaria pdf</span>
                  </a>
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/5cbd371a-14ee-4a7a-9918-cad24f92c9a1.pdf"
                    target="_blank"
                    class="pr-15"
                  >
                    <span class="mt-0 pt-0 linkStyling">Norwegian pdf</span>
                  </a>
                  <br />
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/54de3314-880b-49a0-bc07-8dd98e33514d.pdf"
                    target="_blank"
                    class="pr-15"
                  >
                    <span class="mt-0 pt-0 linkStyling">Italian pdf</span>
                  </a>
                  <a
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/d8de7ccd-c676-4538-87da-7b7bb7c5ac60.pdf"
                    target="_blank"
                    class="pr-15"
                  >
                    <span class="mt-0 pt-0 linkStyling">Romanian pdf</span>
                  </a>
                </v-col> -->
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- Images -->
      <v-col ols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <v-row>
          <v-col cols="5">
            <v-img
              contain
              height="auto"
              src="../assets/happy-university-students.png"
            ></v-img>
          </v-col>
          <v-col cols="5">
            <v-img
              contain
              height="auto"
              src="../assets/PISA-Volume-VI-blog.png"
            ></v-img>
          </v-col>
          <v-col cols="5">
            <v-img contain height="auto" src="../assets/Reset-Pic1.png"></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SmartGoals from "@/components/SmartGoalPoster.vue";
import SmartGoalDialog from "@/components/SmartGoalPosterDialog.vue";
import ContactForm from "../components/ContactForm.vue";
export default {
  components: {
    SmartGoals,
    SmartGoalDialog,
    ContactForm,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.linkStyling {
  color: rgb(19, 79, 113);
}
</style>
