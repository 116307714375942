<template >
  <v-dialog v-model="newsModel">
    <pre>{{news}}</pre>
    <template>
      <v-card>
        <v-img :src="news.mediaurl">

        </v-img>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import Home from "../views/Home.vue"
export default {
  components:{
    Home
  },
  
  
  
  
  data(){
    return{
      news:[],
      newsModel: false,
      

    }
  },
  methods:{
    opennewsModel(news){
      this.news = news;

      this.newsModel = true;
    },
    closenewsModel(){
      this.newsModel = false
    }
  }
}
</script>

