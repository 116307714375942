<template>
  <v-row>
  <SmartGoalDialog ref="openSmartDialog"></SmartGoalDialog>
    <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" v-if="$vuetify.breakpoint.mdAndDown">
      <v-row>
        <v-col cols="12" class="pb-0 mt-5">
          <v-img src="smartGoalPoster/DIGI0.png" width="500" height="auto" ></v-img>
        </v-col>

        
        <v-col cols="12" v-for="(goal, goalIndex) in smartGoals" :key="goalIndex" @click="$refs.openSmartDialog.openDialog(goal)">
        <!-- <v-col cols="12" v-for="(goal, goalIndex) in smartGoals" :key="goalIndex"> -->
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 12 : 0" style="cursor:pointer;" class="pl-5 pr-5">
              <v-img :src="goal.image" width="500" height="auto"></v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" style="margin-left: 30%;" v-else>
      <v-row>
        <v-col cols="12" class="pb-0 mt-5">
          <v-img src="smartGoalPoster/DIGI0.png" width="500" height="auto" ></v-img>
        </v-col>
        <v-col cols="12" v-for="(goal, goalIndex) in smartGoals" :key="goalIndex" @click="$refs.openSmartDialog.openDialog(goal)">
        <!-- <v-col cols="12" v-for="(goal, goalIndex) in smartGoals" :key="goalIndex"> -->
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 12 : 0" style="cursor:pointer;" class="pl-5 pr-5">
              <v-img :src="goal.image" width="500" height="auto"></v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row> 
    </v-col>
 

  </v-row>
</template>

<script>
import SmartGoalDialog from "@/components/SmartGoalPosterDialog.vue"
export default {
  components: {
    SmartGoalDialog
  },
  data(){
    return {
      smartGoals: [
        { description: "Introduction", value: 1, image: "smartGoalPoster/aR.png"},
        { description: "Digital teaching fundamentals", value: 2, image: "smartGoalPoster/aE.png"},
        { description: "Teachers competencies for digital teaching", value: 3, image: "smartGoalPoster/aS.png"},
        { description: "Tutorial videos on how to apply digital teaching through digital tools", value: 4, image: "smartGoalPoster/aEtwo.png"},
        { description: "List of references", value: 5, image: "smartGoalPoster/aT.png"},
      ]
    }
  }
}
</script>