<template>
  <v-card class="ma-0" flat>
    <ImageDialog ref="openImage"></ImageDialog>
    <v-col cols="12">
      <p class="title text-center pb-5 pt-5 ml-3 mr-3 mainHeaders">Digital Teaching Fundamentals</p>
      <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px;"></v-divider>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12" v-for="(tempTwo, tempTwoIndex) in templateTwoContent" :key="tempTwoIndex" @click="templateTwoChange(tempTwo.value)">
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 12 : 0" style="cursor:pointer; background-color: lightblue;" height="100%" class="pa-2">
              <p class="mb-0">{{tempTwo.description}}</p>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-col>
    <!-- Sub chapter 1 / 6 -->
    <template v-if="subTemplateOneID == 1">
      <v-col cols="12" class="ma-0 pa-0">
        <p class="title text-center pt-5 pb-5 mb-5 mt-5 subHeaders">What is the approachable structure?</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
        <p>
          Distance learning, also called distance education, e-learning, and online learning, 
          form of education in which the main elements include physical separation of teachers and students during instruction and the use of various technologies to 
          facilitate student-teacher and student-student communication.
        </p>
        <p>There are two main types of distance learning: </p>
        <p>
          <strong> Education programs that are delivered entirely online. </strong>
          In this type, colleges and universities allow students to receive instruction only from home, which will require a computer and an internet connection.
        </p>
        <p>
          <strong> Hybrid education programs. </strong>
          Distance learning hybrid programs are those that combine learning both online and in a classroom. 
          Certain skills can't be taught entirely from a distance. 
          Sometimes an instructor has to be physically present in order for students to get the most out of a lecture or demonstration. 
          Sometimes the need to collaborate with fellow students is best achieved in close quarters. 
          Many distance learning colleges understand this and offer hybrid programs. 
          Because the point of distance learning is to offer academic flexibility for working professionals, most of the institutions that offer hybrid programs try to minimize the time spent in physical classrooms. 
          They often achieve this goal by hosting intense weekend retreats that cram all the necessary classroom hours into one big session. 
          This can work best for students because they often prefer to dedicate an entire weekend to completing their studies than a number of weeknights commuting to a school after work. 
          Hybrid programs are sometimes offered in conjunction with online-only courses. 
          Students who like personal interaction with instructors and/or those who can get to a classroom on a weekend or weeknight may prefer a hybrid program. 
          Others who can't make it to the classroom because of work or family obligations or those that prefer to work autonomously can opt to finish their coursework entirely online.
        </p>
        <p>Here are five major approaches for successful digital content implantation are Practitioner, Collaborative, Integrative, Reflective and Inquiry Based Learning</p>
        <p>
          <strong>Practitioner Teaching Approach</strong>
          allows learners to be active in the process of constructing meaning and knowledge, rather than passively receiving information. 
          It fosters critical thinking and provides learners with a learning environment that helps them make connections with their learnings. 
          Teachers have a big role to influence, or create motivating conditions for students, take responsibility for creating problem situations, foster acquisition and retrieval of prior knowledge.
        </p>
        <p>
          <strong>Collaborative Teaching approach</strong>
          is an umbrella term for a variety of educational approaches involving joint intellectual efforts by students, or students and teachers together. 
          Activities vary, but most center on the student's exploration or application of the course material, not simply the teacher's presentation or explication of it. 
          In order to achieve a digital classroom where collaborative learning approach works, teachers must fully understand learner's preferred learning styles and view of learning. 
          Teachers shall use the following strategies properly 
        </p>
        <v-btn class="mb-5 dropDownButton" block @click="toggleApproachableText()">Click to read more </v-btn>
        <div v-if="isApproachableTextVisible">
          <p>
            1. Online cooperation <br>
            2. Jigsaw Method <br>
            3. Think - Pair - Share <br>
            4. Integrated Process Approach <br>
            5. Peer Teaching
          </p>
          <p>
            <strong>Integrative Teaching Approach</strong>
            provides learners with a learning environment that helps them make connections of their learning's across curricula. 
            It focuses on connections rather than teaching isolated facts. 
            To this effect, pedagogy of integration has four objectives:
          </p>
          <p>
            1. Making sense of the learning process <br>
            2. Differentiating matters by relevance <br>
            3. Transforming the learning to practical situations <br>
            4. Associating the learned elements.
          </p>
          <p>
            <strong>Inquiry Based Approach</strong>
            requires more than simply answering questions or getting a right answer. 
            It espouses investigation, exploration, search, quest, research, pursuit, and study. 
            To achieve the desired outcomes using this approach, teachers should to promote:
          </p>
          <p>
            1. Simulations <br>
            2. Demonstrations <br>
            3. Experiments <br>
            4. Field Studies <br>
            5. Project Works
          </p>
          <p>
            <strong>Reflective Teaching Approach</strong>
            means looking at what the teacher and learners do in classroom, thinking about why they do it, and analyzing about it if it works. 
            This is a process of self-evaluation and self-observation.
          </p>
        </div>
      </v-col>
    </template>

    <!-- Sub chapter 2 / 6 -->
    <template v-if="subTemplateOneID == 2">
      <v-col cols="12" class="ma-0 pa-0">
        <p class="title text-center pt-5 pb-5 mb-5 mt-5 subHeaders">How to apply digital engagement?</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
        <p>
          The present students live in a universe of consistent incitement. 
          Regardless of whether it's Xbox, online media, or TV, understudies have ceaseless admittance to profoundly captivating visual data, diversion, and network with companions.
        </p>
        <p>
          How do teachers catch understudies' attention and draw in them in the midst of the multitude of interruptions? 
          The period of the educator as the sage on the stage is finished. 
          Students blossom with issue based, intuitive, genuine learning.
          Classrooms should be similarly as connecting with, intelligent, and energizing as all the contending boosts in students' lives. 
          Placing innovation in students' grasp isn't sufficient to connect the present computerized student seriously.
        </p>
        <p>
          Student engagement models vary, however research and viable experience let us know that undeniable degrees of understudy commitment happen when coming up next are available:
        </p>
        <!-- <v-img :src="studentEngagementModel" alt="Student Engagement Model" aspect-ratio="3" contain @click="$refs.openImage.openImageDialog(studentEngagementModel)"></v-img> -->
        <v-img :src="studentEngagementModel" alt="Student Engagement Model" aspect-ratio="3" contain @click="$refs.openImage.openImageDialog(studentEngagementModel)"></v-img>
      </v-col>
    </template>

    <!-- Sub chapter 3 / 6 -->
    <template v-if="subTemplateOneID == 3">
      <v-col cols="12" class="ma-0 pa-0">
        <p class="title text-center pt-5 pb-5 mb-5 mt-5 subHeaders">How to increase learners motivation and engagement in online education?</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
        <p class="mb-1">
          Moving learning from desks to remote learning mainly means transferring the management of tools to students, who are much more used in using technology.
        </p>
        <p class="mb-1">
          The result that could arise is a conflict between the feeling of freedom (they are not within the walls of a classroom)
          and the frustration of seeing transformed the tools that are usually meant for their entertainment and socialization (i.e. computers and smartphones) into a tedious dispenser of study and exercises.
        </p>
        <p class="mb-1">
          In order for this new perspective to work, the student must be motivated, and a valid way to do this is to consider the opportunities given by <strong>gamification.</strong> <br>
          The screen replaces the printed book and greatly multiplies the possibilities of interaction, for this reason it is sad to see the use of the browser as a channel to tell the students 
          "do the exercises in book number x, y and z and write the results in an email. "
        </p>
        <p class="mb-1">
          Instead, the pupils must be placed at the center of the training process, making them feel the builder of their own learning, which they acquire through direct experiences.
        </p>
        <p class="mb-1">
          The children have always played and, especially with the little ones, the good teachers have always valued the game at an educational level
        </p>
        <p class="mb-1">
          Studies and research have today confirmed the effectiveness of the game strategy even in companies because gamification allows to amplify motivations and to memorize information in a meaningful and longer-term way.
        </p>
        <p class="mb-1">
          Gamification is a digital media term, defined as “the use of game design elements in non-game contexts”. Gamification simply uses the elements of a game.
        </p>
        <p class="mb-1">
          The use of educational games for learning is certainly very much appreciated by students. Games teach, reinforce knowledge, and prove effective in improving skills such as problem-solving. 
        </p>
        <p>
          Game-based teaching focuses primarily on motivating participants towards a goal, while waiting for a reward; be it the learning itself or some kind of incentive to achieve the goal. 
          Obviously this must be applied in consideration of the fact that in learning it is discouraged to have a winner or a loser.
        </p>
        <!-- <p class="mb-0 dropDownText" @click="toggleIntroText()">Some elements of the games that can be used to motivate students and facilitate learning include:</p> -->
        <span class="dropDownText pt-5pb-1" @click="toggleIntroText()">Firstly, it is important to face the advantages but also, the difficulties of distance studying. </span>
       <span v-if="isDisplayed == false">⬇️</span>
       <span v-else>⬆️</span>
       <div v-if="isDisplayed">
          <p class="pl-2">
            <span>- Perception of progress (points / badges / rankings);</span><br>
            <span>- Narrative and characters;</span><br>
            <span>- Immediate feedback;</span><br>
            <span>- Collaborative problem-solving opportunities;</span><br>
            <span>- Learning with increasing challenges;</span><br>
            <span>- Opportunity to master skills and self-identify competences acquired;</span><br>
            <span>- Social connection.</span>
          </p>
          <p class="mb-1">
            If from the book and from a frontal lesson the students can learn notions, with gamification, they can test the theories, transforming the information accumulated into operational skills. 
            E-Learning transformed into a game guarantees attention in execution.
          </p>
          <p>
            In support of teachers, there are many platforms that allow you to easily build educational games. 
            Find the one that's right for you!
          </p>
        </div>
      </v-col>
    </template>

    <!-- Sub chapter 4 / 6 -->
    <template v-if="subTemplateOneID == 4">
      <v-col cols="12" class="ma-0 pa-0">

        <p class="title text-center pt-5 pb-5 mb-5 mt-5 subHeaders">Important points to remember when creating online teaching materials</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
        <v-row>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <p class="pa-5 mb-0" :class="(this.pointCardChosen == 1) ? 'selectedCard' : 'notSelectedCard'" @click="selectThisCard(1)" >1. Master the technology</p>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <p class="pa-5 mb-0" :class="(this.pointCardChosen == 2) ? 'selectedCard' : 'notSelectedCard'" @click="selectThisCard(2)">2. Remain flexible and be ready to meet unexpected issues</p>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <p class="pa-5 mb-0" :class="(this.pointCardChosen == 3) ? 'selectedCard' : 'notSelectedCard'" @click="selectThisCard(3)">3. Keep and demonstrate a strong presence</p>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <p class="pa-5 mb-0" :class="(this.pointCardChosen == 4) ? 'selectedCard' : 'notSelectedCard'" @click="selectThisCard(4)">4. Set clear objectives the lesson</p>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <p class="pa-5 mb-0" :class="(this.pointCardChosen == 5) ? 'selectedCard' : 'notSelectedCard'" @click="selectThisCard(5)">5. Request regular feedback</p>
           </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <p class="pa-5 mb-0" :class="(this.pointCardChosen == 6) ? 'selectedCard' : 'notSelectedCard'" @click="selectThisCard(6)">6. Consistently check content resources and applications</p>
          </v-col>

          <v-col cols="12" class="pt-15">
            <template v-if="this.pointCardChosen == 1">
              <p class="title font-weight-bold mb-0">1. Master the technology</p>
              <p class="pl-2">
                <span>
                  • This is new to everybody, so be ready to investigate and tell your students you are chipping away at it. 
                  Require an hour to dive more deeply into the innovation. 
                  Most organizations are offering extra preparing at present.
                </span> <br>
                <span>
                  •	Be exceptionally obvious to students concerning where they ought to go for technical help. 
                  Make the contact data promptly accessible and be ready to guide students there assuming that they come to you.
                </span>
              </p>
            </template>
            <template v-if="this.pointCardChosen == 2">
              <p class="title font-weight-bold mb-0">2. Remain flexible and be ready to meet unexpected issues</p>
              <p class="pl-2">
                <span> • There is a risk the technology to fail at some moment, whether it is a video chat not connecting or assignment and/or resource links not working properly.</span><br>
                <span> • Have a plan “B” for all materials you have prepared that rely on technology.</span><br>
                <span> • Be straightforward in your correspondence to understudies about innovation disappointment. </span><br>
                <span class="pl-2">
                  For instance, set up an arrangement that diagrams the moves understudies should make, assuming they can't submit tasks because of specialized issues.
                </span><br>
                <span> • Don’t be afraid to solve technical challenges in real time, such as during synchronous discussions or collaborative real-time activities, to save time. </span>
              </p>
            </template>
            <template v-if="this.pointCardChosen == 3">
              <p class="title font-weight-bold mb-0">3. Keep and demonstrate a strong presence</p>
              <p class="pl-2">
                <span> • Use school or cloud platform chat rather than basic instant message when interacting with students.</span><br>
                <span> • Get the students talking by starting discussions in the board, and then contributing fast, regular, and open responses to their questions.</span><br>
                <span> • Complete your profile with professional and personal traits.</span>
              </p>
            </template>
            <template v-if="this.pointCardChosen == 4">
              <p class="title font-weight-bold mb-0">4. Set clear objectives the lesson</p>
              <p class="pl-2">
                <span> • Monitor participation and contact students individually if they are either not participating or are taking over conversations and not permitting contributions from other individuals.</span><br>
                <span>
                  • Set assumptions for reaction time. For instance, clarify that you will react to messages inside one workday, any other way understudies might anticipate that you should answer an email inside a couple of hours, and withdraw assuming you don’t. 
                  Share resources for students on how to be an online learner. 6. Promote reflection and communication through quality asynchronous discussion
                </span>
                <span>
                  • Online learning is new to the students too. 
                  Make it clear to students how their grade in the course, not set in stone now (support regularly makes up a lot bigger part of the grade than in eye-to-eye classes).
                </span>
              </p>
            </template>
            <template v-if="this.pointCardChosen == 5">
              <p class="title font-weight-bold mb-0">5. Request regular feedback </p>
              <p class="pl-2">
                • Check in with your students to see how things are going. 
                You can do formal or informal surveys to assess attitudes, workload and challenges. 
                Make course correction as necessary — we’re all learning.
              </p>
            </template>
            <template v-if="this.pointCardChosen == 6">
              <p class="title font-weight-bold mb-0">6. Consistently check content resources and applications</p>
              <p class="pl-2">
                <span> • Regularly check all links, resources, modules, and activities. Online content can move or change, which can lead to disengagement.</span><br>
                <span> • Assist students who are having difficulty navigating course links or managing the material spanning across various web pages.</span><br>
                <span> • Model the process of navigating to websites that are not embedded in the course, and demonstrate how to appropriately manage keeping track of navigation when jumping from site to site.</span>
              </p>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <!-- Sub chapter 5 / 6 -->
    <template v-if="subTemplateOneID == 5">
      <v-col cols="12" class="ma-0 pa-0">
        <p class="title text-center pt-5 pb-5 mb-5 mt-5 subHeaders">How to empower learners to learn online?</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
        <p>
          It is a fact that teachers and educators redesigned lessons to housing the restrictions of e-learning and students learned to connect from their beds, kitchens, public transport and parks.
        </p>
        <p>
          Unfortunately, there are a lot more barriers to e-learning than a well-planned lesson.  
        </p>
        <p>
          <span> •  Many students do not have access to reliable technology or internet service. </span><br>
          <span> •  Many others do not have access to adults to support learning the materials at home. </span><br>
          <span> •  Learning Management systems get overwhelmed </span><br>
          <span> •  Technology requires a steep learning curve.</span>
        </p>
        <p class="mb-0">
          We now teach students to learn  new platforms and apps, new technologies, new routines, and navigate new websites.  
        </p>
        <p class="mb-0">
          All without the physical presence of the teachers they have learned to trust to mentor them.
        </p>
        <p class="mb-0">
          The learning curve is high.  For all educators, students, and parents.  
        </p>
        <p>
          This sudden change, without the opportunity to foreshadow and teach the new skills, 
          has left some students feeling like they have <strong>lost control</strong> of their education, and in some cases their entire world. 
        </p>
        <p>What can we do to <strong>empower students?</strong></p>
        <v-img src="smartGoalPoster/empowerLearnersOnline.png" alt="How to empower learners to learn online?" aspect-ratio="3" contain @click="$refs.openImage.openImageDialog('smartGoalPoster/empowerLearnersOnline.png')"></v-img>
      </v-col>
    </template>

    <!-- Sub chapter 6 / 6 -->
    <template v-if="subTemplateOneID == 6">
      <v-col cols="12" class="ma-0 pa-0">
        <p class="title text-center pt-5 pb-5 mb-5 mt-5 subHeaders">A pedagogical model for e-learning</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
        <p> <strong>“The five-stage model of online learning" by Gilly Salmon”</strong></p>
        <p style="font-size: 18px;">
          <a href="https://www.gillysalmon.com/carpe-diem1.html" target="_blank">gillysalmon.com/carpe-diem1</a>
        </p>
        <p>
          <span>Access and motivation: </span>
          <span>
            E-tivities need to concentrate on providing explicit motivation and set the pace and rhythm. 
            E-tivities need to be designed carefully to enable the participants to find their way around the online learning platform whilst taking part irrelevant and authentic tasks. 
            The participants might be nervous at this stage about how participants are expected to behave and who is online with them. 
            Make a start on e-tivities that address these concerns and help people to feel more comfortable. 
            Try to avoid the “Post your first message here and say who you are” type of message. It will frighten some of the participants.
          </span>
        </p>
        <v-img src="smartGoalPoster/PedagogocalModelOfElearning.png" alt="How to empower learners to learn online?" aspect-ratio="3" contain @click="$refs.openImage.openImageDialog('smartGoalPoster/PedagogocalModelOfElearning.png')"></v-img>
      </v-col>
    </template>
  </v-card>
</template>

<script>
import ImageDialog from "@/components/SmartGoalPoster/SmartGoalImageDialog.vue"
export default {
  components: {
    ImageDialog
  },
  data(){
    return {
      isDisplayed: false,
      studentEngagementModel: 'smartGoalPoster/studentEngagementModel.png',
      subTemplateOneID: 1,
      templateTwoContent: [
        { description: "What is the approachable structure?", image: "", value: 1},
        { description: "How to apply digital engagement?", image: "", value: 2},
        { description: "How to increase learners motivation and engagement in online education?", image: "", value: 3},
        { description: "Important points to remember when creating online teaching materials", image: "", value: 4},
        { description: "How to empower learners to learn online?", image: "", value: 5},
        { description: "A pedagogical model for e-learning", image: "", value: 6},
      ],
      // Different Text Toggles.
      isApproachableTextVisible: false,
      isLearnersMoticationalVisible: false,

      // CHosen Card
      pointCardChosen: 1,
    }
  },
  methods: {

    toggleIntroText(){
      console.log("Trigger")
      if(this.isDisplayed == false) {
        this.isDisplayed = true;
        console.log("True")
      } else {
        this.isDisplayed = false;
        console.log("False")
      }
    },
    // For Category 2 sub template swaps
    templateTwoChange(subID){
      this.subTemplateOneID = subID;
    },
    toggleApproachableText(){
      console.log("Triggered")
      if(this.isApproachableTextVisible == false) {
        this.isApproachableTextVisible = true;
      } else {
        this.isApproachableTextVisible = false;
      }
    },

    selectThisCard(selectedCardId){
      this.pointCardChosen = selectedCardId;
    }
  }
}
</script>

<style scoped>
.closeButtonStyling {
  position:fixed; 
  top:30px; 
  right:30px; 
  z-index: 5; 
  border:1px solid black; 
  background-color:rgba(255, 0, 0, 0.2);
}
.returningToTop {
  position:fixed; 
  bottom:30px; 
  right:30px; 
  z-index: 5;
  border:1px solid black; 
  background-color:rgba(255, 0, 0, 0.2); 
}
.outputsTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 50px;
}
.smartGoalDividerPositioning {
  margin-left:1%; 
  margin-bottom:2%;
}
.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}

.subHeaders {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
}

.mainHeaders {
  color: rgb(19, 79, 113);
  font-weight: bold;
  background-color: orange;
}

.dropDownText {
  color: purple;
  font-weight: bold;
  cursor: pointer;
}

.dropDownButton {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113) !important;
}

.selectedCard {
  color: green;
  font-weight: bold;
  background-color: lightyellow;
  border: 1px solid black;
  cursor: pointer;
}

.notSelectedCard {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
  cursor: pointer;
}

</style>