import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"1000px"},model:{value:(_vm.newsCard),callback:function ($$v) {_vm.newsCard=$$v},expression:"newsCard"}},[[_c(VCard,{staticClass:"black--text"},[_c(VAppBar,{staticClass:"d-flex justify-center",attrs:{"color":" titleFontTypeSize primary lighten-4 black--text","dark":""}},[_vm._v(" "+_vm._s(_vm.news.title)+" ")]),_c(VImg,{staticStyle:{"margin-top":"10px"},attrs:{"src":_vm.news.mediaurl,"aspect-ratio":"1.3","black--text":"","height":"auto","contain":""}}),_c(VCardText,{staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"bodyFontTypeSize pa-6",domProps:{"innerHTML":_vm._s(_vm.news.content)}})]),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"to":"/news"}},[_vm._v("News")]),_c(VBtn,{staticClass:"error--text",on:{"click":function($event){return _vm.closeNewsCard()}}},[_vm._v(" Close ")])],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }