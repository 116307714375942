<template>
  <v-dialog v-model="goalDialog" fullscreen persistent >
    <v-card class="ma-0" flat>
      <v-card-title >
        <v-app-bar-nav-icon  style="margin-bottom:-14px; color:red;" class="d-flex justify-center pt-2" v-if="$vuetify.breakpoint.mdAndDown" color="black" @click="openDrawer()">
          <v-img class="d-flex justify-start" height="50px" width="50px" src="../assets/list_black_24dp.svg"></v-img>
        </v-app-bar-nav-icon>   
        <v-spacer />
        <v-btn large class="closeButtonStyling" color="error" icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      
      
      <v-row class="ma-0 pa-0">

        <!-- To push it down -->
        <v-col cols="12" class="ma-0 pa-0 mt-15"></v-col>
        <!-- Computer menu -->
        <template>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3" class="ma-0 pa-0" style="border-right:2px solid grey;" height="100%">
            <v-col cols="12" v-for="(goal, goalIndex) in smartGoals" :key="goalIndex" @click="changeTemplate(goal)" class="ma-0 pa-0">
              <v-hover v-slot:default="{ hover }">
                <v-card :elevation="hover ? 12 : 0" style="cursor:pointer; min-height:150px;" class="pt-6">
                  <v-img :src="goal.image" width="500" height="auto"></v-img>
                </v-card>
              </v-hover>
            </v-col>
          </v-col>

          <template v-if="$vuetify.breakpoint.mdAndDown && drawerIsOpen == true" cols="12" class="ma-0 pa-0">
            <v-card style="cursor:pointer; width: 99vw;" flat class="pb-5 pt-3 px-2">
            <v-col cols="12" v-for="(goal, goalIndex) in smartGoals" :key="goalIndex" @click="changeTemplate(goal)" class="ma-0 pa-0">
                <v-btn block class="pa-5 my-2" style="background-color:rgb(19, 79, 113); color: orange;">{{ goal.title }}</v-btn>
                <!-- <v-img :src="goal.image" width="500" height="auto"></v-img> -->
              </v-col>
              </v-card>
            <v-col cols="12"></v-col>
          </template>
        </template>
        <!-- Phone menu -->
        <!-- <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12" class="ma-0 pa-0"></v-col> -->

        <!-- Content based on template -->
        <v-col cols="12" xl="9" lg="9" md="9" sm="12" xs="12" class="ma-0 pa-0 pa-3">

          <!-- Introduction -->
          <template v-if="goalID == 1" >
            <CategoryOne></CategoryOne>
          </template>

          <!-- Digital teaching fundamentals -->
          <template v-if="goalID == 2">
            <CategoryTwo></CategoryTwo>
          </template>

          <!-- Teachers competencies for digital teaching -->
          <template v-if="goalID == 3">
            <CategoryThree></CategoryThree>
          </template>

          <!-- Tutorial videos on how to apply digital teaching through digital tools -->
          <template v-if="goalID == 4">
            <CategoryFour></CategoryFour>
          </template>

          <!-- List of references -->
          <template v-if="goalID == 5">
            <CategoryFive></CategoryFive>
          </template>

        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import CategoryOne from "@/components/SmartGoalPoster/CategoryOne.vue"
import CategoryTwo from "@/components/SmartGoalPoster/CategoryTwo.vue"
import CategoryThree from "@/components/SmartGoalPoster/CategoryThree.vue"
import CategoryFour from "@/components/SmartGoalPoster/CategoryFour.vue"
import CategoryFive from "@/components/SmartGoalPoster/CategoryFive.vue"
export default {
  components: {
    CategoryOne,
    CategoryTwo,
    CategoryThree,
    CategoryFour,
    CategoryFive
  },
  data(){
    return {
      goalDialog: false,
      // Main Template for the categories
      goalID: 1,
      // Navigation on phone
      drawerIsOpen: false,
      // Sub Templates for topics within the categories - used to make it more organised
      subTemplateOneID: 1,
      subTemplateTwoID: 1,
      subTemplateThreeID: 1,
      subTemplateFourID: 1,
      subTemplateFiveID: 1,
      mainHeader: "Introduction",
      smartGoals: [
        { description: "Introduction", value: 1, image: "smartGoalPoster/aR.png", title: "Readiness and introduction"},
        { description: "Digital teaching fundamentals", value: 2, image: "smartGoalPoster/aE.png", title: "E-teaching fundamentals"},
        { description: "Teachers competencies for digital teaching", value: 3, image: "smartGoalPoster/aS.png", title: "Skills for digital teachers"},
        { description: "Tutorial videos on how to apply digital teaching through digital tools", value: 4, image: "smartGoalPoster/aEtwo.png", title: "Educational tutorial videos"},
        { description: "Resources", value: 5, image: "smartGoalPoster/aT.png", title: "Treasure Resources"},
      ],
      templateOneContent: [
        { description: "Introduction", image: "", value: 1},
      ],
      templateTwoContent: [
        { description: "What is the approachable structure?", image: "", value: 1},
        { description: "How to apply digital engagement?", image: "", value: 2},
        { description: "How to increase learners motivation and engagement in online education?", image: "", value: 3},
        { description: "How to empower learners to learn online?", image: "", value: 4},
      ],
      templateThreeContent: [
        { description: "SELFIE for teachers", image: "", value: 1},
        { description: "Competencies for digital content creation", image: "", value: 2},
        { description: "Specific tools on how to evaluate learners performance", image: "", value: 3},
        { description: "Suggestions on how to use open educational resources efficiently", image: "", value: 4},
      ],
      templateFourContent: [
        { description: "How to create an interactive digital lesson", image: "", value: 1},
        { description: "How to create engaging presentations", image: "", value: 2},
        { description: "How to monitor group projects", image: "", value: 3},
        { description: "How to create and evaluate online tests/quizzes/surveys", image: "", value: 4},
        { description: "How to create video content", image: "", value: 5},
        { description: "How to create digital portfolios", image: "", value: 6},
        { description: "How to create infographic, posters, flyers and badges", image: "", value: 7},
        { description: "Gamification as engaging learning methodolody", image: "", value: 8},
      ]
    }
  },
  methods: {
    // Opening the dialog
    openDialog(goalDataFromParent){
      this.goalDialog = true;
      this.goalID = goalDataFromParent;
      this.mainHeader = goalDataFromParent.description;
    },

    closeDialog(){
      this.goalDialog = false;
    },

    // For Main Template Swaps
    changeTemplate(newTemplate){
      this.goalID = newTemplate.value;
      this.mainHeader = newTemplate.description;
    },

    // For Category 2 sub template swaps
    templateTwoChange(subID){
      this.subTemplateOneID = subID;
    },

    // Open dialog navigation
    openDrawer(){
      if(this.drawerIsOpen == false){
        this.drawerIsOpen = true;
      } else {
        this.drawerIsOpen = false;
      }
    }
  }
}
</script>

<style scoped>
.closeButtonStyling {
  position:fixed; 
  top:30px; 
  right:30px; 
  z-index: 5; 
  border:1px solid black; 
  background-color:rgba(255, 0, 0, 0.2);
}
.returningToTop {
  position:fixed; 
  bottom:30px; 
  right:30px; 
  z-index: 5;
  border:1px solid black; 
  background-color:rgba(255, 0, 0, 0.2); 
}
.outputsTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 50px;
}
.smartGoalDividerPositioning {
  margin-left:1%; 
  margin-bottom:2%;
}

.ActiveImage {
  background-color: lightblue;
}

</style>