<template>
  <div>
    <v-navigation-drawer class="primary lighten-4" v-model="drawer" temporary app>
      <v-list nav dense>
        <ContactForm  ref="ContactFormButton"></ContactForm>
        <v-list-item-group>
          <v-list-item style="margin-top:20px; margin-left:-2px ">
            <v-app-bar-nav-icon color="black" @click="closeDrawer()">
              <v-img contain width="45px" class="d-flex justify-start"   src="../assets/list_black_24dp.svg"></v-img>  
            </v-app-bar-nav-icon> 
          </v-list-item>
          <v-list-item class="drawerMarginLeft listItemFont">
              <v-list-item-title>
                <v-btn plain href="https://app.followup.prios.no/#/login" target="_blank">
                  Log In
                </v-btn>
              </v-list-item-title>
          </v-list-item>
          <!-- Just buttons -->
          <v-list-item class="drawerMarginLeft listItemFont">
            <v-list-item-title>
              <v-btn plain to="/">
                Home
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="drawerMarginLeft listItemFont">
            <v-list-item-title>
              <v-btn plain to="/about">
                About
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="drawerMarginLeft listItemFont">
            <v-list-item-title>
              <v-btn plain to="/news">
                News
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item class="drawerMarginLeft listItemFont">
            <v-list-item-title>
              <v-btn plain to="/events">
                Events
              </v-btn>
            </v-list-item-title>
          </v-list-item> -->
          <v-list-item class="drawerMarginLeft listItemFont">
            <v-list-item-title>
              <v-btn plain to="outputs">
                Outputs
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="drawerMarginLeft listItemFont">
            <v-list-item-title>
              <v-btn plain to="mappingtool">
                Mapping Tools
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <!-- Title by it self and buttons following-->
          <!-- <p>Information</p> -->
          <v-list-item class="drawerMarginLeft listItemFont">
            <v-list-item-title>
              <v-btn plain @click="$refs.ContactFormButton.openDialog()" class="mr-1">
                  Contact Us
                </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> 
  </div>
</template>

<style  scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.listItemFont{
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  color:black !important;
}
.drawerMarginLeft{
  margin-left: 20px;
}
</style>

<script>
import ContactForm from '../components/ContactForm.vue'
export default {
  components:{
    
    ContactForm,
    
  },
  data(){
    return{
      drawer: false,
    }
  },
  methods:{
    openDrawer(){
      this.drawer=true;
    },
    closeDrawer(){
      this.drawer=false;
    }
  },
}
</script>