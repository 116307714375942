<template>
  <nav>
    <v-row>
      <v-col cols="12">
        <v-app-bar height="100px" style = "font-family: 'Poppins', sans-serif;" >
          <v-row class="primary lighten-4" style="margin-left:-16px; margin-right:-31px; margin-bottom: -16px;" >
            <v-col cols="6" xs="6" sm="6" md="6" lg="5" xl="5" href="/"   class="d-flex align-center justify-start ">
              <v-row>
                <v-col cols="2" style="margin-top:14px; margin-left:11px;" class=" d-flex justify-center " >
                  <v-app-bar-nav-icon  style="margin-bottom:-14px; color:red;" class="d-flex justify-center " v-if="$vuetify.breakpoint.smAndDown" color="black" @click="$refs.DrawerButton.openDrawer()">
                    <v-img class="d-flex justify-start" height="24px" width="24px" src="../assets/list_black_24dp.svg"></v-img> 
                  </v-app-bar-nav-icon>          
                </v-col>
                <v-col cols="4" class="d-flex justify-start" style="margin-left:-16px"  width="260px">
                  <a href="/">
                    <v-img contain width="260px" style="height:80px" src="/img/icons/Reset_Logo_600px.png"></v-img>
                  </a>
                </v-col>
              </v-row>
            </v-col>
            
            <v-col  style="height: 100px;" cols="6" xs="6" sm="6" md="6" lg="6" xl="6" class="d-flex align-center justify-end ">
              <ContactForm  ref="ContactFormButton"></ContactForm>
              <v-btn active-class="buttonToggle" v-if="$vuetify.breakpoint.mdAndUp" plain style="font-size:15px;" color="black" class="mr-1" to="/">
                Home
              </v-btn>
              <v-btn active-class="buttonToggle" v-if="$vuetify.breakpoint.mdAndUp" style="font-size:15px;" plain color="black" class="mr-1 " to="/about">
                About
              </v-btn>
              <!-- <v-btn active-class="buttonToggle" v-if="$vuetify.breakpoint.mdAndUp" plain style="font-size:15px;" color="black" class="mr-1" to="/events">
                Events
              </v-btn> -->
              <v-btn active-class="buttonToggle" v-if="$vuetify.breakpoint.mdAndUp" plain style="font-size:15px;" color="black" class="mr-1" to="/outputs">
                Outputs
              </v-btn>
              <v-btn active-class="buttonToggle" v-if="$vuetify.breakpoint.mdAndUp"  style="font-size:15px;" plain color="black" class="mr-1" to="/news">
                News
              </v-btn>
              <v-btn active-class="buttonToggle" v-if="$vuetify.breakpoint.mdAndUp"  style="font-size:15px;" plain color="black" class="mr-1" to="/mappingtool">
                Mapping Tools
              </v-btn>
              <v-btn v-if="$vuetify.breakpoint.mdAndUp" style="font-size:15px;" plain color="black" @click="$refs.ContactFormButton.openDialog()" class="mr-1">
                Contact Us
              </v-btn>
                <v-btn class=" d-flex justify-center loginBorder" style="margin-right:0px"  plain width="70px" href="https://app.followup.prios.no/#/login" target="_blank">
                  <span style="color: black">login</span>
              </v-btn>  
              
            </v-col>
          </v-row>
        </v-app-bar>
        <Drawer ref="DrawerButton"></Drawer>
      </v-col>
    </v-row>
  </nav> 
</template>

<style>
.loginBorder{
  border-style: solid;
  border-color: black;
  border-width: 1px;
}
.buttonToggle {
  background: #bbb8f5 !important;
}



@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
</style> 

<script>
import Drawer from '@/components/Drawer.vue'
import ContactForm from '../components/ContactForm.vue'


  
  export default {
  components:{
    Drawer,
    ContactForm,
    
  },

  data(){
    return{
      drawer: false,
      isActive : false ,
      
    }
  },
  
  methods:{
    openDrawer(){
      this.drawer=true;
    },
    noToggle(){

      this.isActive = false;
    },
    toggle(){
      this.isActive = true;
    }
  },
}
</script>