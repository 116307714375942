import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{model:{value:(_vm.ContactForm),callback:function ($$v) {_vm.ContactForm=$$v},expression:"ContactForm"}},[_c(VCard,[_c(VCardTitle,[_c('span',[_vm._v("Contact Form")])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Name","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"pa-0",attrs:{"label":"E-mail","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"pa-0",attrs:{"label":"Phone","required":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Subject","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)],1),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":"Message","required":"","scrollable":"","hide-details":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"error darken-1 white--text","title":"Lukker skjema"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")])],1),_c(VSpacer),_c(VCol,{staticClass:"d-flex flex-row-reverse"},[_c(VBtn,{staticClass:"success",attrs:{"title":"Sender melding, og lukker skjema."},on:{"click":function($event){return _vm.sendData()}}},[_vm._v(" Send ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }