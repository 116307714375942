<template>
  <v-container style="font-family: 'Source Sans Pro', sans-serif;">
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <h1>About</h1>
      </v-col>
      <v-col cols="12">
        <h3>The OECD report “Education and COVID-19.</h3>
      </v-col>

      <v-col cols="12">
        <p>
          Focusing on the long-term impact of school closures (July,20)” states it takes time to adapt and switch to distance-learning, 
          international reports highlighted the difficulties schools face to integrate the technologies of information and communication into the classroom. 
        </p>
        <div>
          <span>
            This potential learning loss is determined by two concurring factors: <br>
            <li>How much students have learnt during school closures — the “intensive margin” — refers to the efficiency of education continuity solutions.</li>   
            <li>How many students who continued to learn during the school closures —the “extensive margin” —refers to the share of students engaged in the education continuity solutions.</li> 
          </span>
        </div>
        <p>
          Being able to exploit the potential of digital solutions is not only a question of digital skills among teachers and students, 
          but also challenges given school management in order to develop and implement their strategy for Digital school, since successfully use of devices, tools, 
          software does not automatically make teachers perform better and students learn more.
        </p>
      </v-col>
      <v-col cols="12">
        <p style="color:purple; font-weight:bold; cursor:pointer;" @click="showCollection()"> The target groups of this project are schools and its staff and teachers. 
        </p>
        <br>
        <p v-if="displayCollection">
          Summarized the project aims to:  
          <br> 1. Create methods, tools and guidelines within digital pedagogical competences among educators. What are success criteria, evaluate/assess their students in an online environment.   
          <br><br> 2. Create knowledge about and development guidelines for practical use of open educational resources and equip teachers with the skills / knowledge of developing their own digital materials.  
          <br><br> 3. Create easy to use, low and secure E-Communication tool for improved communication between school and home, which require a minimum of digital skills to implement by both schools, teachers, students and parents. 
          <br><br> 4. Create and promote the European label “Digital Friendly Schools”, a certification for the schools that apply the guidelines developed inside the project. The three former outputs will bring to the definition of a set of requirements and steps for acquiring this recognition! 
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Home',

    data(){
      return {
        displayCollection: false
      }
    },
    methods:{
      showCollection(){
        if(this.displayCollection == false) {
          this.displayCollection = true;
          console.log("a", this.displayCollection);
        } else {
          this.displayCollection = false;
          console.log("b", this.displayCollection);
        }
      }
    }
  }
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

p {
  font-size: 20px;
}

span {
  font-size: 20px;
}
</style>