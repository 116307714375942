import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"fullscreen":""},model:{value:(_vm.mappingDialog),callback:function ($$v) {_vm.mappingDialog=$$v},expression:"mappingDialog"}},[_c(VCard,[_c('div',{staticClass:"container"},[_c('h1',[_vm._v("Mapping Tool")]),_c('br'),_c('p',[_vm._v("Write your email and username to start")]),_c(VBtn,{staticClass:"mb-5",on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Close")]),(_vm.mappingTemplate == 1)?[_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"label":"email"},model:{value:(_vm.mappingEmail),callback:function ($$v) {_vm.mappingEmail=$$v},expression:"mappingEmail"}}),_c(VTextField,{attrs:{"label":"username"},model:{value:(_vm.mappingUsername),callback:function ($$v) {_vm.mappingUsername=$$v},expression:"mappingUsername"}})],1)],1),_c(VBtn,{staticClass:"primary ml-3 mb-3",attrs:{"disabled":!_vm.mappingEmail || !_vm.mappingUsername},on:{"click":function($event){return _vm.checkIfEmailGotContent()}}},[_vm._v("Take Mapping Tools")])]:_vm._e(),(_vm.mappingTemplate == 2)?[(_vm.userForm && _vm.addedAnswers.length != 0)?_c('div',[(_vm.userForm.sections.filter((i) => i.sub_category).length == 0)?_c('div',[_c(VTabs,{staticStyle:{"border-radius":"10px"},attrs:{"hide-slider":""},model:{value:(_vm.formSectionTabs),callback:function ($$v) {_vm.formSectionTabs=$$v},expression:"formSectionTabs"}},[_vm._l((_vm.userForm.sections),function(section,index){return _c(VTab,{key:index,staticClass:"tabStyling",style:({
                  background:
                    'linear-gradient(to right, #90EE91 ' +
                    _vm.calculateSectionProgress(section.id) +
                    '%, #eceff180 ' +
                    _vm.calculateSectionProgress(section.id) +
                    '%)',
                })},[(_vm.calculateSectionProgress(section.id))?_c('span',[_vm._v(_vm._s(section.name ? section.name : section.title)+" ")]):_c('span',[_vm._v(_vm._s(section.name ? section.name : section.title))])])}),_vm._l((_vm.userForm.sections),function(section,index){return _c(VTabItem,{key:index},[_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,{staticClass:"ma-0 pa-0",attrs:{"cols":"12","xl":"10","lg":"10","md":"10","sm":"12","xs":"12"}},_vm._l((section.questions),function(question,questionIndex){return _c(VContainer,{key:questionIndex,staticClass:"fu-container-styling mt-4"},[_c(VRow,[_c(VCol,{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('p',{staticStyle:{"font-size":"16px","font-weight":"bold"}},[_c('span',[_vm._v(_vm._s(question.title))]),_c(VMenu,{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"ml-3",staticStyle:{"transform":"translateY(-3px)"},attrs:{"color":"primary","text":"","icon":"","small":""},on:{"click":function($event){_vm.selectedQuestionInfo = question}}},on),[_c(VIcon,{attrs:{"title":"Read question description and score help text","size":"30"}},[_vm._v(" info ")])],1)]}}],null,true)},[_c(VCard,{staticClass:"pa-3 font-weight-regular body-2 br-10",attrs:{"width":"500px"}},[(_vm.selectedQuestionInfo)?_c('div',[_c('p',[_c('b',[_vm._v("Question description:")])]),_c('p',[_vm._v(" "+_vm._s(_vm.selectedQuestionInfo.description)+" ")]),_c('p',[_c('b',[_vm._v("Score explanation:")])]),_c('ol',_vm._l((JSON.parse(
                                        _vm.selectedQuestionInfo.options
                                      )),function(helpText,helpTextIndex){return _c('li',{key:helpTextIndex},[_vm._v(" "+_vm._s(helpText.label)+" ")])}),0)]):_vm._e()])],1)],1),(question.type == 'q_scale')?_c('div',[(
                                JSON.parse(question.options).map(
                                  (i) => i.weight
                                ).length == 0
                              )?_c('div',[_c(VRadioGroup,{attrs:{"disabled":_vm.setQuestionAnswerVariable(question.id)
                                    .answered,"row":""},model:{value:(
                                  _vm.setQuestionAnswerVariable(question.id).value
                                ),callback:function ($$v) {_vm.$set(_vm.setQuestionAnswerVariable(question.id), "value", $$v)},expression:"\n                                  setQuestionAnswerVariable(question.id).value\n                                "}},[_c(VRadio,{attrs:{"label":"1","value":"1"}}),_c(VRadio,{attrs:{"label":"2","value":"2"}}),_c(VRadio,{attrs:{"label":"3","value":"3"}}),_c(VRadio,{attrs:{"label":"4","value":"4"}}),_c(VRadio,{attrs:{"label":"5","value":"5"}})],1)],1):_c('div',[_c(VRadioGroup,{attrs:{"disabled":_vm.setQuestionAnswerVariable(question.id)
                                    .answered,"row":""},model:{value:(
                                  _vm.setQuestionAnswerVariable(question.id).value
                                ),callback:function ($$v) {_vm.$set(_vm.setQuestionAnswerVariable(question.id), "value", $$v)},expression:"\n                                  setQuestionAnswerVariable(question.id).value\n                                "}},[_c(VRadio,{attrs:{"label":"1","value":"1"}}),_c(VRadio,{attrs:{"label":"2","value":"2"}}),_c(VRadio,{attrs:{"label":"3","value":"3"}}),_c(VRadio,{attrs:{"label":"4","value":"4"}}),_c(VRadio,{attrs:{"label":"5","value":"5"}})],1)],1)]):(question.type == 'q_multiple_choice')?_c('div',[(
                                _vm.setQuestionAnswerVariable(question.id)
                                  .answered == false
                              )?_c('div',_vm._l((JSON.parse(
                                  question.options
                                )),function(item,index){return _c('label',{key:index},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(
                                    _vm.setQuestionAnswerVariable(question.id)
                                      .value
                                  ),expression:"\n                                    setQuestionAnswerVariable(question.id)\n                                      .value\n                                  ",modifiers:{"lazy":true}}],staticClass:"ml-2 mr-2",attrs:{"type":"radio","name":'question-' + question.id},domProps:{"value":item.weight,"checked":_vm._q(
                                    _vm.setQuestionAnswerVariable(question.id)
                                      .value
                                  ,item.weight)},on:{"change":function($event){_vm.$set(_vm.setQuestionAnswerVariable(question.id)
                                      , "value", item.weight)}}}),_c('span',[_vm._v(_vm._s(item.label))])])}),0):_c('div',_vm._l((JSON.parse(
                                  question.options
                                )),function(item,index){return _c('label',{key:index},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(
                                    _vm.setQuestionAnswerVariable(question.id)
                                      .value
                                  ),expression:"\n                                    setQuestionAnswerVariable(question.id)\n                                      .value\n                                  ",modifiers:{"lazy":true}}],staticClass:"ml-2 mr-2",attrs:{"type":"radio","name":'question-' + question.id,"disabled":true},domProps:{"value":item.weight,"checked":_vm._q(
                                    _vm.setQuestionAnswerVariable(question.id)
                                      .value
                                  ,item.weight)},on:{"change":function($event){_vm.$set(_vm.setQuestionAnswerVariable(question.id)
                                      , "value", item.weight)}}}),_c('span',{staticStyle:{"color":"grey"}},[_vm._v(_vm._s(item.label))])])}),0)]):(question.type == 'q_checkboxes')?_c('div',_vm._l((JSON.parse(
                                question.options
                              )),function(item,index){return _c(VCheckbox,{key:index,attrs:{"multiple":"","disabled":_vm.setQuestionAnswerVariable(question.id)
                                  .answered,"label":item.label,"value":index + '-' + item.weight},model:{value:(
                                _vm.setQuestionAnswerVariable(question.id).value
                              ),callback:function ($$v) {_vm.$set(_vm.setQuestionAnswerVariable(question.id), "value", $$v)},expression:"\n                                setQuestionAnswerVariable(question.id).value\n                              "}})}),1):_c('div',[_c(VTextField,{attrs:{"disabled":_vm.setQuestionAnswerVariable(question.id)
                                  .answered,"label":"Write your answer here"},model:{value:(
                                _vm.setQuestionAnswerVariable(question.id).value
                              ),callback:function ($$v) {_vm.$set(_vm.setQuestionAnswerVariable(question.id), "value", $$v)},expression:"\n                                setQuestionAnswerVariable(question.id).value\n                              "}})],1)])],1)],1)}),1)],1)],1)})],2)],1):_c('div',[_c(VTabs,{staticStyle:{"border-radius":"10px"},attrs:{"hide-slider":"","active-class":"isActive"},model:{value:(_vm.formSectionTabs),callback:function ($$v) {_vm.formSectionTabs=$$v},expression:"formSectionTabs"}},[_vm._l((_vm.userForm.sections.filter(
                  (i) => !i.sub_category
                )),function(section,index){return _c(VTab,{key:index,staticClass:"tabStyling",style:(_vm.gradientStyle(section.id))},[_vm._v(" "+_vm._s(section.name ? section.name : section.title)+" ")])}),_vm._l((_vm.userForm.sections.filter(
                  (i) => !i.sub_category
                )),function(section,index){return _c(VTabItem,{key:index},[(
                    section.form_intro_page && section.form_intro_page == 1
                  )?_c('div',[(section.description)?_c(VContainer,{staticClass:"fu-container-styling mt-4"},[_c('p',[_vm._v(_vm._s(section.description))])]):_vm._e(),_vm._l((section.questions),function(question,questionIndex){return _c(VContainer,{key:questionIndex,staticClass:"fu-container-styling mt-4"},[(
                        section.form_intro_page &&
                        section.form_intro_page == 1
                      )?_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[(question.type == 'q_front_select')?_c('div',[_c('p',[_vm._v(_vm._s(question.title))]),_c(VSelect,{attrs:{"items":JSON.parse(question.options),"item-text":"label","item-value":"label","label":"Select one","disabled":_vm.setQuestionAnswerVariable(question.id).answered},model:{value:(
                              _vm.setQuestionAnswerVariable(question.id).value
                            ),callback:function ($$v) {_vm.$set(_vm.setQuestionAnswerVariable(question.id), "value", $$v)},expression:"\n                              setQuestionAnswerVariable(question.id).value\n                            "}})],1):(question.type == 'q_front_checkbox')?_c('div',[_c('p',[_vm._v(_vm._s(question.title))]),_vm._l((JSON.parse(
                              question.options
                            )),function(item,index){return _c(VCheckbox,{key:index,attrs:{"multiple":"","disabled":_vm.setQuestionAnswerVariable(question.id).answered,"label":item.label,"value":item.label},model:{value:(
                              _vm.setQuestionAnswerVariable(question.id).value
                            ),callback:function ($$v) {_vm.$set(_vm.setQuestionAnswerVariable(question.id), "value", $$v)},expression:"\n                              setQuestionAnswerVariable(question.id).value\n                            "}})})],2):_c('div',[_c('p',[_vm._v(_vm._s(question.title))]),_c(VTextField,{attrs:{"disabled":_vm.setQuestionAnswerVariable(question.id).answered,"label":"Write your answer here"},model:{value:(
                              _vm.setQuestionAnswerVariable(question.id).value
                            ),callback:function ($$v) {_vm.$set(_vm.setQuestionAnswerVariable(question.id), "value", $$v)},expression:"\n                              setQuestionAnswerVariable(question.id).value\n                            "}})],1)])],1):_vm._e()],1)})],2):_c('div',[_c(VLayout,{attrs:{"justify-start":""}},[_c(VFlex,{attrs:{"xs2":""}},[_c(VNavigationDrawer,{staticStyle:{"width":"100%","z-index":"0"},attrs:{"permanent":""}},[_c(VList,{staticClass:"py-0",attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.userForm.sections.filter(
                                (i) => i.sub_category == section.id
                              )),function(subSection,subIndex){return _c(VListItem,{key:subIndex,on:{"click":function($event){_vm.selectedChildCategory = subSection}}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":_vm.calculateSectionProgress(subSection.id) <
                                    1
                                      ? 'error'
                                      : _vm.calculateSectionProgress(
                                          subSection.id
                                        ) < 99
                                      ? 'yellow'
                                      : 'success'}},[_vm._v(" mdi-record ")])],1),_c(VListItemContent,[_c('p',[_vm._v(_vm._s(subSection.name))])])],1)}),1)],1)],1)],1),(_vm.selectedChildCategory)?_c(VFlex,{attrs:{"xs12":""}},_vm._l((_vm.selectedChildCategory.questions),function(question,questionIndex){return _c(VContainer,{key:questionIndex,staticClass:"fu-container-styling mt-4"},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{staticClass:"pa-1",attrs:{"xs12":""}},[_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(question.title)+" "),_c(VMenu,{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticStyle:{"transform":"translateY(-3px)"},attrs:{"color":"primary","text":"","icon":"","small":""},on:{"click":function($event){_vm.selectedQuestionInfo = question}}},on),[_c(VIcon,{attrs:{"title":"Read question description and score help text","size":"30"}},[_vm._v(" mdi-information ")])],1)]}}],null,true)},[_c(VCard,{staticClass:"pa-3 font-weight-regular body-2 br-10",attrs:{"width":"500px"}},[(_vm.selectedQuestionInfo)?_c('div',[_c('p',[_c('b',[_vm._v("Question description:")])]),_c('p',[_vm._v(" "+_vm._s(_vm.selectedQuestionInfo.description)+" ")]),_c('p',[_c('b',[_vm._v("Score explanation:")])]),_c('ol',_vm._l((JSON.parse(
                                          _vm.selectedQuestionInfo.options
                                        )),function(helpText,helpTextIndex){return _c('li',{key:helpTextIndex},[_vm._v(" "+_vm._s(helpText.label)+" ")])}),0)]):_vm._e()])],1)],1),(question.type == 'q_scale')?_c('div',[(
                                  JSON.parse(question.options).map(
                                    (i) => i.weight
                                  ).length == 0
                                )?_c('div',[_c(VRadioGroup,{attrs:{"disabled":_vm.setQuestionAnswerVariable(question.id)
                                      .answered,"row":""},model:{value:(
                                    _vm.setQuestionAnswerVariable(question.id)
                                      .value
                                  ),callback:function ($$v) {_vm.$set(_vm.setQuestionAnswerVariable(question.id)
                                      , "value", $$v)},expression:"\n                                    setQuestionAnswerVariable(question.id)\n                                      .value\n                                  "}},[_c(VRadio,{attrs:{"label":"1","value":"1"}}),_c(VRadio,{attrs:{"label":"2","value":"2"}}),_c(VRadio,{attrs:{"label":"3","value":"3"}}),_c(VRadio,{attrs:{"label":"4","value":"4"}}),_c(VRadio,{attrs:{"label":"5","value":"5"}})],1)],1):_c('div',[_c(VRadioGroup,{attrs:{"disabled":_vm.setQuestionAnswerVariable(question.id)
                                      .answered,"row":""},model:{value:(
                                    _vm.setQuestionAnswerVariable(question.id)
                                      .value
                                  ),callback:function ($$v) {_vm.$set(_vm.setQuestionAnswerVariable(question.id)
                                      , "value", $$v)},expression:"\n                                    setQuestionAnswerVariable(question.id)\n                                      .value\n                                  "}},[_c(VRadio,{attrs:{"label":"1","value":"1"}}),_c(VRadio,{attrs:{"label":"2","value":"2"}}),_c(VRadio,{attrs:{"label":"3","value":"3"}}),_c(VRadio,{attrs:{"label":"4","value":"4"}}),_c(VRadio,{attrs:{"label":"5","value":"5"}})],1)],1)]):(question.type == 'q_multiple_choice')?_c('div',[_c(VRadioGroup,{attrs:{"disabled":_vm.setQuestionAnswerVariable(question.id)
                                    .answered,"row":""},model:{value:(
                                  _vm.setQuestionAnswerVariable(question.id).value
                                ),callback:function ($$v) {_vm.$set(_vm.setQuestionAnswerVariable(question.id), "value", $$v)},expression:"\n                                  setQuestionAnswerVariable(question.id).value\n                                "}},_vm._l((JSON.parse(
                                    question.options
                                  )),function(item,index){return _c(VRadio,{key:index,attrs:{"label":item.label,"value":item.weight}})}),1)],1):(question.type == 'q_checkboxes')?_c('div',_vm._l((JSON.parse(
                                  question.options
                                )),function(item,index){return _c(VCheckbox,{key:index,attrs:{"multiple":"","disabled":_vm.setQuestionAnswerVariable(question.id)
                                    .answered,"label":item.label,"value":index + '-' + item.weight},model:{value:(
                                  _vm.setQuestionAnswerVariable(question.id).value
                                ),callback:function ($$v) {_vm.$set(_vm.setQuestionAnswerVariable(question.id), "value", $$v)},expression:"\n                                  setQuestionAnswerVariable(question.id).value\n                                "}})}),1):_c('div',[_c(VTextField,{attrs:{"disabled":_vm.setQuestionAnswerVariable(question.id)
                                    .answered,"label":"Write your answer here"},model:{value:(
                                  _vm.setQuestionAnswerVariable(question.id).value
                                ),callback:function ($$v) {_vm.$set(_vm.setQuestionAnswerVariable(question.id), "value", $$v)},expression:"\n                                  setQuestionAnswerVariable(question.id).value\n                                "}})],1)])],1)],1)}),1):_vm._e()],1)],1)])})],2)],1),_c(VBtn,{staticClass:"mt-3 ml-3",attrs:{"color":"primary","disabled":!_vm.addedAnswers.filter((i) => i.value && i.answered == false)
                .length != 0,"right":""},on:{"click":function($event){return _vm.sendFormResponse()}}},[_vm._v("Save Answers")]),_c(VBtn,{staticClass:"ml-6 mt-3 success",on:{"click":function($event){return _vm.calculateFormData()}}},[_vm._v("Submit (Crucial)")]),_c('div',{staticClass:"pb-3"})],1):_vm._e(),_c(VDialog,{model:{value:(_vm.pdfResultDisplayDialog),callback:function ($$v) {_vm.pdfResultDisplayDialog=$$v},expression:"pdfResultDisplayDialog"}},[(_vm.formPdfData)?_c(VCard,{staticClass:"pa-5"},[_c('MappingFormResults',{attrs:{"pdfData":_vm.formPdfData}})],1):_vm._e()],1)]:_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }