<template>
  <v-app>
    <VisitorTracker></VisitorTracker>
    <NavBar></NavBar>
    <v-main>
      <router-view/>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>


<script>
import NavBar from'@/components/NavBar'
import Footer from'@/components/Footer'
import VisitorTracker from "@/components/VisitTracker/VisitorTracker.vue"
export default {
  name: 'App',
  components:{
    NavBar,
    Footer,
    VisitorTracker
  },

  data() {
    return{
      
    }
  },
};
</script>
