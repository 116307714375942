<template >
  <v-dialog
    v-model="newsCard"
    transition="dialog-bottom-transition"
    max-width="1000px">
    <template>
      <v-card class=" black--text ">
      <v-app-bar
      
      color=" titleFontTypeSize primary lighten-4 black--text"
      dark
      class="d-flex justify-center"  >
      {{news.title}}
      </v-app-bar>
        <v-img
        style="margin-top:10px;" 
        :src="news.mediaurl"
        aspect-ratio="1.3" 
        black--text
        height="auto"
        contain>  
        </v-img>
      <v-card-text style="padding:0px;">
        <!-- v-html="" allows the use of tags inside the text that is fetched from server. -->
        <div  class="bodyFontTypeSize pa-6"  v-html="news.content" ></div>
    </v-card-text>
    <v-card-actions class="justify-end">
    <!-- closes the dialog since it has the persistent attribute attached to the dialog. -->
        <v-btn to="/news" >News</v-btn>
        <v-btn
        class="error--text"
        @click="closeNewsCard()">
        Close
        </v-btn>
      </v-card-actions>
      </v-card>
    </template>
    </v-dialog>
</template>

<style scoped>

.titleFontTypeSize{
  color:black !important;
  font-size:22px;
  font-family: 'Source Sans Pro', sans-serif;
}
.bodyFontTypeSize{
  color:black !important;
  font-size:18px;
  font-family: 'Source Sans Pro', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
</style>

<script>
import Home from "../views/Home.vue"
export default {
  components:{
    Home
  },
  
  
  
  data(){
    return{
      news:[],
      newsCard: false
      

    }
  },
  methods:{
    openNewsCard(news){
      this.news = news;

      this.newsCard = true;
    },
    closeNewsCard(){
      this.newsCard = false
    }
  }
}
</script>

