<template>
  <v-snackbar class="d-flex justify-start" style="font-family: 'Poppins', sans-serif; max-width:100%;"
      timeout="100000"
      v-model="snackbar"
      :multi-line="multiLine"
      color="white black--text">
      <v-row style="height: auto; width: 330px;" class=" pa-1 d-flex justify-center"> 
        <v-col class=" pa-0 d-flex justify-center" cols="12" style="font-size:18px;">{{ text }}</v-col>
        <v-col class="pa-0" cols="12"></v-col>
        <v-col cols="12" style="font-size:14px;">
          <v-row>
            <v-col cols="11">
               Subscribe to our Newsletter to get information about upcoming activities and project progress!
            </v-col>
            <v-col style="padding:0px; margin:0px;" cols="1">
              <v-btn style="padding:0px; margin:0px; min-width:20px; max-height:20px; background-color: #ff712e;" @click="snackbar= false" >X</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-text-field label="E-mail" filled outlined class="" background-color="black"></v-text-field>
        <v-col class="pa-0" cols="12">
          <v-row >
            <v-col style="margin-top:-14px;"  class=" pb-3 d-flex justify-center" cols="12">
              <v-btn @click="snackbar = false" color="primary">
                Subscribe
              </v-btn>  
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-snackbar>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
.fontWhite{
  color: white;
};
.backgroundWhite{
  background-color: white;
}
</style>

<script>
  export default {
    data: () => ({
      multiLine: true,
      snackbar: true,
      text: `Newsletter`,
    }),
  }
</script>