<template>
  <v-card class="ma-0" flat>
    <ImageDialog ref="openImage"></ImageDialog>
    <v-col cols="12">
      <p class="title text-center pb-5 pt-5 ml-3 mr-3 mainHeaders">Teachers competencies for digital teaching</p>
      <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px;"></v-divider>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col ols="12" xl="3" lg="3" md="3" sm="12" xs="12" v-for="(tempThree, tempThreeIndex) in templateThreeContent" :key="tempThreeIndex" @click="templateThreeChange(tempThree.value)">
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 12 : 0" style="cursor:pointer; background-color: lightblue;" height="100%" class="pa-2">
              <p class="mb-0">{{tempThree.description}}</p>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-col>
    <!-- Sub chapter 1 / 5 -->
    <template v-if="subTemplateOneID == 1">
      <v-col cols="12" class="ma-0 pa-0">
        <p class="title text-center pb-5 pt-5 mb-5 mt-5 subHeaders">SELFIE for teachers</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
      </v-col>
      <v-col cols="12" class="ma-0 pa-0">
        <p style="font-size:18px" class="mb-0">
          <a href="https://ec.europa.eu/education/schools-go-digital_en" target="_blank">ec.europa.eu</a>
        </p>
        <v-icon>mdi-lock</v-icon>
        <p class="title font-weight-bold mb-0">What is SELFIE?</p>
        <p>
          SELFIE is a free, online tool to help schools assess how they use digital technologies for innovative and effective learning. 
          With SELFIE, schools can take a snapshot of where they stand in their use of digital technologies, taking on board views of teachers, students and school leaders. 
          This self-assessment process can help start a dialogue within the school on potential areas for improvement. SELFIE also allows a school to monitor its progress over time.
        </p>
        <p class="title font-weight-bold mb-0">What does SELFIE measure?</p>
        <p>
          Through a series of questions for teachers, school leaders and students, SELFIE measures the way digital technologies are used for teaching and learning at your school. 
          Questions for school leaders focus mainly on strategies and practices at school level related to the use of digital technologies. 
          The questions for teachers are to mainly capture teaching practices and for students their experience and learning practices related to the use of digital technologies. 
          Schools can customize the tool by adding questions that suit their context.
        </p>
        <p class="title font-weight-bold mb-0">Who is behind SELFIE?</p>
        <p>
          SELFIE has been developed by the European Commission together with a panel of education experts and practitioners from European countries. 
          More than 67000 school leaders, teachers and students across Europe tested the beta version of the tool in late 2017.
        </p>
      </v-col>
    </template>

    <!-- Sub chapter 2 / 5 -->
    <template v-if="subTemplateOneID == 2">
      <v-col cols="12" class="ma-0 pa-0">
        <p class="title text-center pb-5 pt-5 mb-5 mt-5 subHeaders">Competencies for digital content creation</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
        <v-img src="smartGoalPoster/compentenciesForDigitalContentCreation.png" alt="Competencies for digital content creation" aspect-ratio="3" contain @click="$refs.openImage.openImageDialog('smartGoalPoster/compentenciesForDigitalContentCreation.png')"></v-img>
      </v-col>
    </template>

    <!-- Sub chapter 3 / 5 -->
    <template v-if="subTemplateOneID == 3">
      <v-col cols="12" class="ma-0 pa-0">
        <p class="title text-center pb-5 pt-5 mb-5 mt-5 subHeaders">Ways of how to evaluate learners’ performance</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
        <p>Here we provide suggestions for assessing student learning and performance as well as ways to clarify your expectations and performance criteria to students.</p>
        <v-img src="smartGoalPoster/evaluateLearnersPerformance.png" alt="Ways of how to evaluate learners’ performance" aspect-ratio="3" contain @click="$refs.openImage.openImageDialog('smartGoalPoster/evaluateLearnersPerformance.png')"></v-img>
      </v-col>
    </template>

    <!-- Sub chapter 4 / 5 -->
    <template v-if="subTemplateOneID == 4">
      <v-col cols="12" class="ma-0 pa-0">
        <p class="title text-center pb-5 pt-5 mb-5 mt-5 subHeaders">Ways for formative and summative evaluation</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
        <v-img src="smartGoalPoster/waysToFormativeAndSummativeEvaluation.png" alt="Ways for formative and summative evaluation" aspect-ratio="3" contain @click="$refs.openImage.openImageDialog('smartGoalPoster/waysToFormativeAndSummativeEvaluation.png')"></v-img>
      </v-col>
    </template>

     <!-- Sub chapter 5 / 5 -->
    <template v-if="subTemplateOneID == 5">
      <v-col cols="12" class="ma-0 pa-0">
        <p class="title text-center pb-5 pt-5 mb-5 mt-5 subHeaders">Tips on how to use open educational recourses efficiently?</p>
        <v-divider class="smartGoalDividerPositioning" width="98%" style="padding: 2px; margin-bottom:30px;"></v-divider>
        <v-img src="smartGoalPoster/tips.png" alt="Tips on how to use open educational resources efficiently" aspect-ratio="3" contain @click="$refs.openImage.openImageDialog('smartGoalPoster/tips.png')"></v-img>
        <p class="mt-15 mb-0 pl-2">
          <span> •  Host A Live Open Educational Resource Workshop how to use this repository;</span><br>
          <span> •  Before building, it verifies usage rights of the resources</span><br>
          <span> •  Ensure that online resources are aligned with your learning objective if Creative Commons license says that you can modify;</span><br>
          <span> •  Encourage your students to self-guided exploration;</span><br>
          <span> •  Create an Open Educational Resource Repository for your students;</span>
        </p>
      </v-col>
    </template>
  </v-card>
</template>

<script>
import ImageDialog from "@/components/SmartGoalPoster/SmartGoalImageDialog.vue"
export default {
  components: {
    ImageDialog
  },
  data(){
    return {
      studentEngagementModel: 'smartGoalPoster/studentEngagementModel.png',
      subTemplateOneID: 1,
      templateThreeContent: [
        { description: "SELFIE for teachers", image: "", value: 1},
        { description: "Competencies for digital content creation", image: "", value: 2},
        { description: "Ways of how to evaluate learners’ performance", image: "", value: 3},
        { description: "Ways for formative and summative evaluation", image: "", value: 4},
        { description: "Tips on how to use open educational recourses efficiently?", image: "", value: 5},
      ],
    }
  },
  methods: {
    // For Category 2 sub template swaps
    templateThreeChange(subID){
      this.subTemplateOneID = subID;
    }
  }
}
</script>

<style scoped>
.closeButtonStyling {
  position:fixed; 
  top:30px; 
  right:30px; 
  z-index: 5; 
  border:1px solid black; 
  background-color:rgba(255, 0, 0, 0.2);
}
.returningToTop {
  position:fixed; 
  bottom:30px; 
  right:30px; 
  z-index: 5;
  border:1px solid black; 
  background-color:rgba(255, 0, 0, 0.2); 
}
.outputsTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 50px;
}
.smartGoalDividerPositioning {
  margin-left:1%; 
  margin-bottom:2%;
}

.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}

.subHeaders {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
}

.mainHeaders {
  color: rgb(19, 79, 113);
  font-weight: bold;
  background-color: orange;
}

.dropDownText {
  color: purple;
  font-weight: bold;
  cursor: pointer;
}


</style>