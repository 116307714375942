<template>
  <v-dialog v-model="imageDialog" width="60vw"  overlay-color="black" overlay-opacity="0.8">
    <v-card flat >
      <!-- <v-card-title>
        <v-spacer></v-spacer>
        <v-btn large class="closeButtonStyling" color="error" icon @click="closeImageDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title> -->
      <!-- <v-btn icon @click="closeImageDialog()"><v-icon>close</v-icon></v-btn> -->
      <v-img :src="imageFromParent" alt="Student Engagement Model" height="90vh" width="90vw" contain @click="closeImageDialog()"></v-img>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data(){
    return {
      imageDialog: false,
      imageFromParent: ""
    }
  },
  methods: {
    // Open Image Dialog
    openImageDialog(imageURL){
      this.imageDialog = true;
      this.imageFromParent = imageURL;
    },

    // Close Image Dialog
    closeImageDialog(){
      this.imageDialog = false;
    }
  }
}
</script>


<style scoped>

* {
  overflow: hidden;
}
</style>