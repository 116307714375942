import { render, staticRenderFns } from "./CategoryFour.vue?vue&type=template&id=972f6480&scoped=true&"
import script from "./CategoryFour.vue?vue&type=script&lang=js&"
export * from "./CategoryFour.vue?vue&type=script&lang=js&"
import style0 from "./CategoryFour.vue?vue&type=style&index=0&id=972f6480&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "972f6480",
  null
  
)

export default component.exports