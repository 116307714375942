import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c('SmartGoalDialog',{ref:"openSmartDialog"}),(_vm.$vuetify.breakpoint.mdAndDown)?_c(VCol,{attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c(VRow,[_c(VCol,{staticClass:"pb-0 mt-5",attrs:{"cols":"12"}},[_c(VImg,{attrs:{"src":"smartGoalPoster/DIGI0.png","width":"500","height":"auto"}})],1),_vm._l((_vm.smartGoals),function(goal,goalIndex){return _c(VCol,{key:goalIndex,attrs:{"cols":"12"},on:{"click":function($event){return _vm.$refs.openSmartDialog.openDialog(goal)}}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{staticClass:"pl-5 pr-5",staticStyle:{"cursor":"pointer"},attrs:{"elevation":hover ? 12 : 0}},[_c(VImg,{attrs:{"src":goal.image,"width":"500","height":"auto"}})],1)]}}],null,true)})],1)})],2)],1):_c(VCol,{staticStyle:{"margin-left":"30%"},attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c(VRow,[_c(VCol,{staticClass:"pb-0 mt-5",attrs:{"cols":"12"}},[_c(VImg,{attrs:{"src":"smartGoalPoster/DIGI0.png","width":"500","height":"auto"}})],1),_vm._l((_vm.smartGoals),function(goal,goalIndex){return _c(VCol,{key:goalIndex,attrs:{"cols":"12"},on:{"click":function($event){return _vm.$refs.openSmartDialog.openDialog(goal)}}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{staticClass:"pl-5 pr-5",staticStyle:{"cursor":"pointer"},attrs:{"elevation":hover ? 12 : 0}},[_c(VImg,{attrs:{"src":goal.image,"width":"500","height":"auto"}})],1)]}}],null,true)})],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }